import React from "react";
import { useController } from "react-hook-form";
import { ChromePicker } from "react-color";

import { ButtonProps, InputProps, RHCommonProps, RHWrapper, Box, FormLabel, Flex, Button, BaseBoxProps } from "Atoms";
import { useSwitch } from "Hooks";

type FormInputProps = {
    onlyShowLabelWithValue?: boolean;
    pickerProps?: BaseBoxProps;
    isHEXColor?: boolean;
} & RHCommonProps &
    InputProps &
    ButtonProps;

export const RHColorPicker: React.FC<FormInputProps> = ({
    name,
    control,
    placeholder,
    formLabel,
    helperText,
    isMandatory = false,
    isFullWidth = true,
    onlyShowLabelWithValue = false,
    isDisabled = false,
    wrapperProps,
    pickerProps,
    isHEXColor = false,
    ...rest
}) => {
    const { open, onToggle } = useSwitch();

    const {
        field: { onChange, value, ref },
        fieldState: { isTouched, error }
    } = useController({
        name,
        control
    });

    let shouldShowLabel = true;
    if (onlyShowLabelWithValue) {
        shouldShowLabel = !!value && !!formLabel;
    } else {
        shouldShowLabel = !!formLabel;
    }

    const defaultValue = "rgba(0,0,0,1)";

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={undefined}
            helperText={helperText}
            wrapperProps={wrapperProps}
            isMandatory={isMandatory}
        >
            <Flex>
                {formLabel && <FormLabel>{formLabel}</FormLabel>}
                <Button padding={"5px"} onClick={() => !isDisabled && onToggle()} variant="outline" h="auto" {...rest}>
                    <Box width="36px" height="14px" borderRadius="2px" background={value ?? defaultValue} />
                </Button>
                {open && (
                    <Flex direction="column" zIndex={"sticky"} position="relative" h="25px">
                        <Box onClick={onToggle} position="fixed" top="0px" right="0px" bottom="0px" left="0px" />
                        <Box position="relative" bottom="130px" left="10px" {...pickerProps}>
                            {/** @ts-ignore */}
                            <ChromePicker
                                color={value ?? defaultValue}
                                onChange={value => {
                                    onChange(
                                        isHEXColor
                                            ? value.hex
                                            : `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${value.rgb.a})`
                                    );
                                }}
                            />
                        </Box>
                    </Flex>
                )}
            </Flex>
        </RHWrapper>
    );
};
