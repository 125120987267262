import React from "react";
import { useController } from "react-hook-form";
import Select, { ActionMeta, Props, ValueType } from "react-select";

import { RHCommonProps, RHExtendedWrapperProps } from "Atoms";
import {
    RHSelectInputValueRemove,
    RHSelectInputMenu,
    RHSelectInputDropdownIndicator,
    RHSelectInputClearIndicator
} from "./components";
import { useRHSelectInputStyles } from "./useRHSelectInputStyles";
import { RHWrapper } from "../RHWrapper";

export type SelectOption = {
    label: string;
    value: string;
    isDisabled?: boolean;
    isFixed?: boolean;
    visited?: boolean;
};

type SelectTypes = Omit<Props, "name"> & { isClearable?: boolean };
type RHSelectInputProps = {} & RHCommonProps & SelectTypes & RHExtendedWrapperProps;

export const RHSelectInput: React.FC<RHSelectInputProps> = ({
    name,
    control,
    formLabel,
    helperText,
    isMandatory = false,
    isFullWidth = true,
    wrapperProps,
    options,
    isMulti,
    isClearable = true,
    isBeta = false,
    tooltipLabelText,
    ...rest
}) => {
    const {
        field: { onChange: onChangeInternal, onBlur, value, ref }
    } = useController({
        name,
        control,
        defaultValue: isMulti ? [] : ""
    });

    const onChange = (option: ValueType<SelectOption, boolean>, actionMeta: ActionMeta<SelectOption>) => {
        if (isMulti) {
            const selectedOptions = option as SelectOption[];
            switch (actionMeta.action) {
                case "select-option": {
                    onChangeInternal(selectedOptions.map(v => v.value));
                    break;
                }
                case "remove-value": {
                    onChangeInternal(value.filter((v: any) => v != actionMeta?.removedValue?.value));
                    break;
                }
                case "clear": {
                    onChangeInternal([]);
                    break;
                }
            }
        } else {
            const selectedOption = option as SelectOption;
            switch (actionMeta.action) {
                case "select-option": {
                    onChangeInternal(selectedOption.value);
                    break;
                }
            }
        }
    };

    const styles = useRHSelectInputStyles();

    // Values passed down to react-select
    const selectedValues = isMulti
        ? options.filter((opt: SelectOption) => value?.includes(opt.value))
        : options.find((opt: SelectOption) => opt.value == value);

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={formLabel}
            helperText={helperText}
            wrapperProps={wrapperProps}
            isMandatory={isMandatory}
            isBeta={isBeta}
            tooltipLabelText={tooltipLabelText}
        >
            {/** @ts-ignore */}
            <Select
                ref={ref}
                onChange={onChange}
                defaultValue={isMulti ? [] : ""}
                onBlur={onBlur}
                value={selectedValues}
                name={name}
                fullWidth={isFullWidth}
                options={options}
                isMulti={isMulti}
                isClearable={isMulti ? isClearable : false}
                menuPortalTarget={document.body}
                styles={styles}
                {...rest}
                components={{
                    Menu: RHSelectInputMenu,
                    MultiValueRemove: RHSelectInputValueRemove,
                    DropdownIndicator: RHSelectInputDropdownIndicator,
                    ClearIndicator: RHSelectInputClearIndicator
                }}
            />
        </RHWrapper>
    );
};
