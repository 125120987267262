import React, { useState, useEffect, forwardRef } from "react";
import styled from "styled-components";

import { Box, BaseBoxProps } from "../Box/Box";

interface IImage {
    src?: string;
    fallbackSrc?: string;
    alt?: string;
    onLoad?: (event: Event) => void;
    onError?: (event: Event | string) => void;
    htmlWidth?: string | number;
    htmlHeight?: string | number;
    ignoreFallback?: boolean;
}

export type ImageProps = IImage & BaseBoxProps;

type NativeImageProps = {
    htmlWidth?: string | number;
    htmlHeight?: string | number;
    alt?: string;
};

export type BannerImageProps = {
    backgroundImage: string;
};

export const useHasImageLoaded = ({
    src,
    onLoad,
    onError
}: {
    src?: string;
    onLoad?: (event: Event) => void;
    onError?: (event: string | Event) => void;
}) => {
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
        const image = new window.Image();
        image.src = src || "";

        image.onload = event => {
            setHasLoaded(true);
            onLoad && onLoad(event);
        };

        image.onerror = event => {
            setHasLoaded(false);
            onError && onError(event);
        };
    }, [src, onLoad, onError]);

    return hasLoaded;
};

const NativeImage: React.FC<NativeImageProps> = forwardRef(({ htmlWidth, htmlHeight, alt, ...props }, ref) => (
    <img width={htmlWidth} height={htmlHeight} ref={ref as any} alt={alt} {...props} />
));

export const Image: React.FC<ImageProps> = forwardRef(
    ({ src, fallbackSrc, ignoreFallback, onError, onLoad, ...rest }, ref) => {
        const hasLoaded = useHasImageLoaded({ src, onError, onLoad });

        let imageProps;

        if (ignoreFallback) {
            imageProps = { src, onLoad, onError };
        } else {
            imageProps = { src: hasLoaded ? src : fallbackSrc };
        }

        return (
            <Box
                as={NativeImage}
                width={rest.width || "100%"}
                height={rest.height || "auto"}
                ref={ref}
                {...imageProps}
                {...rest}
            />
        );
    }
);

export const BannerImage = styled.div<BannerImageProps>`
    background-image: url("${props => props.backgroundImage}");
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
`;
