import React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { useController } from "react-hook-form";
import moment, { Moment } from "moment";

import { Input, InputProps, RHCommonProps, RHWrapper } from "Atoms";
import { StyledDatePickerWrapper } from "./components/StyledDatePickerWrapper";

type DatePickerProps = Omit<ReactDatePickerProps, "onChange">;

type RHDatePickerInputProps<T> = {
    transformBeforeOnChange: (moment: Moment) => T;
    inputProps?: InputProps | undefined;
} & RHCommonProps &
    DatePickerProps;

export const RHDatePickerInput = <T extends unknown>({
    formLabel,
    helperText,
    isMandatory,
    locale = "sv-se",
    isFullWidth = false,
    wrapperProps,
    name,
    transformBeforeOnChange,
    inputProps,
    control,
    ...rest
}: RHDatePickerInputProps<T>) => {
    const {
        field: { onChange, value, ref }
    } = useController({
        name,
        control
    });

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={formLabel}
            helperText={helperText}
            wrapperProps={wrapperProps}
            isMandatory={isMandatory}
        >
            <StyledDatePickerWrapper fullWidth={isFullWidth}>
                {/** @ts-ignore */}
                <DatePicker
                    {...rest}
                    ref={ref}
                    customInput={<Input size="lg" rounded="lg" fullWidth={isFullWidth} {...inputProps} />}
                    locale={locale}
                    selected={value ? moment(value) : null}
                    // Here we have the opportunity to transform the moment into anything we want
                    // This works really well with react-hook-forms that have a Date type since we don't usually send Moments over Mutations & Queries
                    // this should reduce the need for an extra, temporary field or a transformer function
                    onChange={val =>
                        !!transformBeforeOnChange && !!val ? onChange(transformBeforeOnChange(val)) : onChange(val)
                    }
                />
            </StyledDatePickerWrapper>
        </RHWrapper>
    );
};
