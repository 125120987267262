import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styled from "styled-components";

export const StyledPhoneInput = styled(PhoneInput)`
    &.react-tel-input > .form-control {
        border: 1px !important;
        border-color: ${props => props.theme.colors.gray[200]} !important;
        border-style: solid !important;
        border-radius: ${props => props.theme.radii.lg} !important;
        background: ${props => props.theme.colors.white} !important;
        height: ${props => props.theme.sizes[12]} !important;
        padding-right: ${props => props.theme.sizes[4]} !important;
        line-height: 3rem !important;
        font-size: ${props => props.theme.fontSizes.lg} !important;
        transition: all 0.25s !important;
        outline: none !important;
        appearance: none !important;
        width: 100% !important;
    }

    &.react-tel-input > .flag-dropdown {
        background-color: transparent !important;
        border: none !important;
    }

    &.react-tel-input > .flag-dropdown > .selected-flag:focus {
        box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6) !important;
        transition: all 0.25s !important;
    }

    &.react-tel-input .flag-dropdown > .selected-flag:hover {
        background: transparent !important;
    }

    &.react-tel-input > .flag-dropdown.open > .selected-flag {
        background: transparent !important;
    }

    &.react-tel-input > .form-control:focus {
        border-color: ${props => props.theme.colors.blue[500]} !important;
        box-shadow: 0 0 0 1px ${props => props.theme.colors.blue[500]} !important;
    }
`;
