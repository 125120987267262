import React, { useEffect, useState } from "react";
import { unstable_usePrompt as usePrompt } from "react-router-dom";
/** I know this says unstable - but at present is the only way */

//TODO: don't like but this is a temporary solution - Improvement ticket when available
/**
 * [BROWSER_CONFIRM] - This is keep the same behavior as the react-router-dom Prompt
 * * This is a temporary solution until the react-router-dom Prompt is updated to allow for a function as the when prop
 * @param param0 
 * @returns 
 */
export const BrowserPrompt: React.FC<{ when: boolean; message: string }> = ({ when = false, message }) => {
    const [whenCondition, setWhenCondition] = useState<boolean>(when);
    const [messageCondition, setMessageCondition] = useState<string>(message);

    useEffect(() => {
        setWhenCondition(when);
        setMessageCondition(message);
    }, [when, message]);

    usePrompt({
        message: messageCondition,
        when: ({ currentLocation, nextLocation }) => whenCondition && currentLocation.pathname !== nextLocation.pathname
    });

    return null;
};
