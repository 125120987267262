import React, { PropsWithChildren } from "react";

import { BaseBoxProps, Spinner, NewContainer, Center } from "Atoms";

const LoadingMessage = (props: BaseBoxProps & PropsWithChildren) => {
    const children = props?.children;
    return (
        <NewContainer w="100%" h="100%">
            <Center w="100%" h="100%">
                <Spinner size="large">{!!children && children}</Spinner>
            </Center>
        </NewContainer>
    );
};

export default LoadingMessage;
