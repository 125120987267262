import React from "react";
import { isMobile } from "react-device-detect";

import { BaseBoxProps, Box } from "Atoms";
import { useModalContext } from "../Modal";

export type ModalBodyProps = { isScrolling?: boolean } & BaseBoxProps;

export const ModalBody: React.FC<ModalBodyProps> = ({ color, ...props }) => {
    const { isScrolling } = useModalContext();
    let styles = {};
    if (isScrolling) {
        styles = { overflowY: "auto", maxHeight: "calc(80vh - 10em)" };
    }

    return <Box {...styles} flex="1" zIndex={1400} p={6} {...props} />;
};
