import React, { PropsWithChildren } from "react";
import styled, { keyframes } from "styled-components";

type Props = {
    color: string;
    size: "small" | "medium" | "large";
    margin: string;
    position: string;
} & PropsWithChildren;

const sizes = {
    small: "25px",
    medium: "50px",
    large: "75px"
};

// minitinysmallmediumlargebighugemassive
const StyledSpinner = styled.svg<Partial<Props>>`
    animation: rotate 1s linear infinite;
    margin: ${props => props.margin || "40px"};
    width: ${props => (props.size ? sizes[props.size] : "50px")};
    height: auto;

    & .path {
        stroke: ${props => props.color || props.theme.colors.primary};
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`;

export const Spinner: React.FC<Partial<Props>> = props => (
    <StyledSpinner viewBox="0 0 50 50" {...props}>
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="3" />
    </StyledSpinner>
);
