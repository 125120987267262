import React, { useState, useEffect, PropsWithChildren } from "react";
import { MdBrokenImage } from "@react-icons/all-files/md/MdBrokenImage";

import { Box, Text, Image, Spinner } from "Atoms";
import { PseudoBox, Flex } from "Atoms";

type Props = {
    isUploading: boolean;
    imageUrl?: string;
    icon: any;
    iconColour: string;
    hasErrors: boolean;
    dropZoneMessage?: string;
};

const DropZoneDisplayWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <PseudoBox
            as="span"
            _after={{
                content: "{}",
                display: "inline-block",
                verticalAlign: "middle"
            }}
        >
            <Flex direction="column" justifyContent="center" alignItems="center">
                {children}
            </Flex>
        </PseudoBox>
    );
};

export const DropZoneDisplay: React.FC<Props> = ({
    isUploading,
    imageUrl,
    icon,
    iconColour,
    hasErrors,
    dropZoneMessage
}) => {
    const [brokenImageLink, setBrokenImageLink] = useState<boolean>(false);

    const onBrokenImage = () => {
        setBrokenImageLink(true);
    };

    useEffect(() => {
        if (imageUrl) {
            setBrokenImageLink(false);
        }
    }, [imageUrl]);

    if (isUploading) {
        return <Spinner color="#4299E1" />;
    }

    if (imageUrl && !brokenImageLink && !hasErrors) {
        return <Image onError={onBrokenImage} src={imageUrl} alt="Image Preview" width="200px" />;
    }

    if (imageUrl && !brokenImageLink && hasErrors) { 
        return (
            <DropZoneDisplayWrapper>
                <Image onError={onBrokenImage} src={imageUrl} alt="Image Preview" width="200px" />
                <Flex alignItems="center">
                    <Box as={!brokenImageLink ? icon : MdBrokenImage} fontSize="1.5rem" color={iconColour} mt={2} />
                    <Text ml={2} mt={2}>{dropZoneMessage}</Text>
                </Flex>
            </DropZoneDisplayWrapper>
        );
    }

    const hasBrokenImageAndError = brokenImageLink && hasErrors;

    if (hasBrokenImageAndError) {
        return (
            <DropZoneDisplayWrapper>
                <Box as={icon} fontSize="1.5rem" color={iconColour} />
                <Text mt={2}>{dropZoneMessage}</Text>
            </DropZoneDisplayWrapper>
        );
    }
    return (
        <DropZoneDisplayWrapper>
            <>
                <Box as={!brokenImageLink ? icon : MdBrokenImage} fontSize="1.5rem" color={iconColour} />
                <Text mt={2}>{!!dropZoneMessage ? dropZoneMessage : ""}</Text>
            </>
        </DropZoneDisplayWrapper>
    );
};
