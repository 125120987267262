import React, { useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
    NewContainer,
    RHCheckBoxInput,
    Stack,
    CustomRadioButton,
    RHDatePickerInput,
    RHFormInput,
    RHNumericInput,
    RHRadioButtonGroup,
    RHSelectInput,
    RHCreatableSelectInput,
    RHSwitchInput,
    RHTextAreaInput,
    RHTimeFormInput,
    RHGroupSelectInput,
    RHCounterInput,
    RHCheckBoxGroupInput,
    LoadingSkeleton,
    Text,
    RHColorPicker,
    RHImageUploadDropZone,
    Box,
    NewTruncate as Truncate
} from "Atoms";
import { useForm } from "react-hook-form";
import { Moment } from "moment";
import { useMothershipMutation } from "Hooks";
import { UPLOAD_FILE } from "GraphQLMutations";

type FormValues = {
    testCheckbox: boolean;
    testDate: Date;
    testTextInput: string;
    testNumericInput: number;
    testRadioValue?: number;
    testSelectSingle?: string;
    testSelectCreate?: string[];
    testSelectMulti: string[];
    testSelectCreateMulti: string[];
    listOfCreatedItems?: string[];
    testShopId: string;
    testShopIdsMulti: string[];
    testNumBuns: number;
    testNumCinnamonBuns: number;
    testGroupCheckbox: string[];
    testGroupCheckboxSingle: string;
    testColor: any;
    testImageUrlDragAndClick?: any;
    testImageUrlSelectOnly?: any;
};

const defaultValues = {};

export const RHTestPage = () => {
    const presetStartingHours = ["08:00", "09:00", "11:00", "12:00"];

    const validationSchema = Yup.object().shape<FormValues>({
        testCheckbox: Yup.boolean().oneOf([true]).required(),
        testDate: Yup.date().required(),
        testTextInput: Yup.string().email().required(),
        testNumericInput: Yup.number().required(),
        testRadioValue: Yup.number(),
        testSelectSingle: Yup.string(),
        testSelectMulti: Yup.array<string>().required().min(1),
        testSelectCreate: Yup.array<string>().required().min(1),
        testSelectCreateMulti: Yup.array<string>().required().min(1),
        testShopId: Yup.string(),
        testShopIdsMulti: Yup.array<string>().required().min(1),
        testNumBuns: Yup.number().min(1).max(5),
        testNumCinnamonBuns: Yup.number().max(5),
        testGroupCheckbox: Yup.array<string>().required().min(1),
        testGroupCheckboxSingle: Yup.string().required(),
        testColor: Yup.object().required()
    });

    const {
        control,
        reset,
        setValue,
        setError,
        clearErrors,
        getValues,
        formState: { isSubmitting, errors },
        handleSubmit
    } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        mode: "onBlur",
        defaultValues
    });

    /** Our standard upload to amazon client
     * [folderType] is the folder in which the image will be stored
     * [companyId] is also in the pathway to the folder
     */
    const [uploadImageFile] = useMothershipMutation(UPLOAD_FILE);

    const values = getValues();
    console.log("ERRORS", errors);
    console.log("values", values);

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    return (
        <NewContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack stretch={4} p={4}>
                    <Truncate showExpand={true} wrapperProps={{ width: "175px" }}>
                        BELOW if it is just for gallery selection then disable the whole thing!!
                    </Truncate>
                    <RHImageUploadDropZone
                        setError={setError}
                        clearErrors={clearErrors}
                        formLabel={"Background Image - Allow drag and drop + select"}
                        control={control}
                        name="testImageUrlDragAndClick"
                        dropZoneText="clickOrDragImage"
                        companyId={"chopchopBoland"}
                        uploadMutation={uploadImageFile}
                    />
                    <RHImageUploadDropZone
                        setError={setError}
                        clearErrors={clearErrors}
                        formLabel={"Background Image - Select Only"}
                        control={control}
                        name="testImageUrlSelectOnly"
                        dropZoneText="select" //  There is no text in translation files for this so it's up to whoever writes it!
                        companyId={"chopchopBoland"}
                        isDragAndDropDisabled
                        uploadMutation={uploadImageFile}
                    />
                    <RHCheckBoxInput control={control} isMandatory name="testCheckbox">
                        This is a checkbox
                    </RHCheckBoxInput>
                    <RHDatePickerInput
                        isMandatory
                        control={control}
                        formLabel="Överenskommen sista startdatum"
                        locale="sv-SE"
                        name="testDate"
                        transformBeforeOnChange={(moment: Moment) => moment.toDate()}
                    />
                    <RHDatePickerInput
                        isMandatory
                        control={control}
                        formLabel="Överenskommen sista startdatum"
                        locale="en-GB"
                        name="testDate"
                        transformBeforeOnChange={(moment: Moment) => moment.toDate()}
                    />
                    <RHFormInput
                        control={control}
                        isMandatory
                        placeholder="hello my placeholder"
                        onlyShowLabelWithValue
                        formLabel="hello"
                        name="testTextInput"
                        helperText="We will never share your email"
                    />
                    <RHNumericInput control={control} isMandatory name="testNumericInput" />
                    <RHRadioButtonGroup
                        control={control}
                        activeThemeColor="blue"
                        name="testRadioValue"
                        marginTop={2}
                        value={1}
                        rounded={5}
                        isMandatory
                    >
                        <CustomRadioButton value={1}>1</CustomRadioButton>
                        <CustomRadioButton value={2}>2</CustomRadioButton>
                        <CustomRadioButton value={3}>3</CustomRadioButton>
                    </RHRadioButtonGroup>
                    <RHSelectInput
                        control={control}
                        isMandatory
                        name="testSelectSingle"
                        noOptionsMessage={() => "van"}
                        options={[
                            {
                                label: "van",
                                value: "van"
                            },
                            {
                                label: "martin",
                                value: "martin",
                                isFixed: true
                            },
                            {
                                label: "luke",
                                value: "luke",
                                isDisabled: true
                            }
                        ]}
                    />
                    <RHSelectInput
                        control={control}
                        name="testSelectMulti"
                        isMulti
                        isMandatory
                        noOptionsMessage={() => "van"}
                        formLabel="hello"
                        options={[
                            {
                                label: "van",
                                value: "van"
                            },
                            {
                                label: "martin",
                                value: "martin",
                                isFixed: true
                            },
                            {
                                label: "luke",
                                value: "luke",
                                isDisabled: true
                            }
                        ]}
                    />
                    <RHCreatableSelectInput
                        control={control}
                        name="testSelectCreateMulti"
                        formLabel="Create something Multi"
                        placeholder="Write or click to add options"
                        formatCreateLabel={(input: string) => `Add... ${input}`}
                        options={[
                            {
                                label: "van",
                                value: "1"
                            },
                            {
                                label: "martin",
                                value: "2",
                                isFixed: true
                            },
                            {
                                label: "luke",
                                value: "3",
                                isDisabled: true
                            }
                        ]}
                        isMulti
                        isMandatory
                    />
                    <RHCreatableSelectInput
                        control={control}
                        name="testSelectCreate"
                        formLabel="Create something Single"
                        placeholder="Write or click to add a single option"
                        formatCreateLabel={(input: string) => `Add... ${input}`}
                        options={[
                            {
                                label: "van",
                                value: "1"
                            },
                            {
                                label: "martin",
                                value: "2",
                                isFixed: true
                            },
                            {
                                label: "luke",
                                value: "3",
                                isDisabled: true
                            }
                        ]}
                        isMandatory
                    />
                    <RHGroupSelectInput
                        control={control}
                        name="testShopIds"
                        formLabel="Shops grouped by company"
                        isMandatory
                        options={[
                            {
                                label: "Company 1",
                                options: [
                                    { label: "Shop 11", value: "shop11" },
                                    { label: "Shop 12", value: "shop12" }
                                ]
                            },
                            {
                                label: "Company 2",
                                options: [
                                    { label: "Shop 21", value: "shop21" },
                                    { label: "Shop 22", value: "shop22" }
                                ]
                            }
                        ]}
                        placeholder={"Select"}
                    />
                    <RHGroupSelectInput
                        control={control}
                        name="testShopIdsMulti"
                        formLabel="Shops grouped by company. Multi"
                        options={[
                            {
                                label: "Company 1",
                                options: [
                                    { label: "Shop 11", value: "shop11" },
                                    { label: "Shop 12", value: "shop12" }
                                ]
                            },
                            {
                                label: "Company 2",
                                options: [
                                    { label: "Shop 21", value: "shop21" },
                                    { label: "Shop 22", value: "shop22" }
                                ]
                            }
                        ]}
                        placeholder={"Select"}
                        isMulti
                    />
                    <RHTextAreaInput isMandatory control={control} name="testTextArea" fullWidth />
                    <RHTimeFormInput
                        options={presetStartingHours}
                        control={control}
                        placeholder={"HH:MM"}
                        name={"testTimeInput"}
                        isMandatory
                    />
                    <RHSwitchInput name="testSwitch" isMandatory control={control} />
                    <RHCounterInput
                        name="testNumBuns"
                        control={control}
                        min={0}
                        max={5}
                        formLabel="How many buns?(max 5)"
                        isMandatory
                    />
                    <RHCounterInput
                        name="testNumCinnamonBuns"
                        formLabel="Cinnamon Buns(input is read only)"
                        control={control}
                        min={1}
                        defaultValue={1}
                        isReadOnly
                        themeColor="blue"
                        image="https://s3-eu-west-1.amazonaws.com/qopla-dev/chopchopBoland/shop/photo-1523198205441-99fac53d157f.jfif"
                    />
                    <RHCheckBoxGroupInput
                        groupHeader="Group checkbox (Multi check)"
                        control={control}
                        name="testGroupCheckbox"
                        options={[
                            {
                                value: "123",
                                label: (
                                    <Text
                                        // Shadow and styles to indicate that this is a complex element
                                        color="green.100"
                                        style={{
                                            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            backgroundColor: "black"
                                        }}
                                    >
                                        NUMBERS
                                    </Text>
                                )
                            },
                            { value: "ABC", label: "ALPHA", isDisabled: true },
                            { value: "XYZ", label: "ENDALPHA", isDisabled: true, isFixed: true },
                            { value: "LMN", label: "ANOTHER", isFixed: true }
                        ]}
                    />
                    <RHCheckBoxGroupInput
                        groupHeader="Group checkbox (Single check)"
                        control={control}
                        name="testGroupCheckboxSingle"
                        isMulti={false}
                        options={[
                            { value: "123", label: "NUMBERS" },
                            { value: "ABC", label: "ALPHA" },
                            { value: "XYZ", label: "ENDALPHA", isDisabled: true }
                        ]}
                    />
                    <Text>Loading skeleton with header</Text>
                    <LoadingSkeleton columns={3} rows={3} hasHeader gridProps={{ templateColumns: "1fr  2fr 2fr" }} />
                    <Text>Loading skeleton with 5 rows</Text>
                    <LoadingSkeleton columns={1} rows={5} />

                    <RHColorPicker name="testColor" formLabel="Test color" control={control} />
                    <button type="submit">Sign in</button>
                </Stack>
            </form>
        </NewContainer>
    );
};
