import React, { Children, cloneElement } from "react";
import * as StyledSystem from "styled-system";

import { getValidChildren } from "Utils";
import { BaseBoxProps } from "../Box/Box";
import { Flex, FlexProps } from "../Flex/Flex";

interface IStack {
    isInline?: boolean;
    isReversed?: boolean;
    direction?: FlexProps["direction"];
    stretch?: StyledSystem.MarginProps["margin"];
    align?: FlexProps["align"];
    justify?: FlexProps["justify"];
}

export type StackProps = IStack & BaseBoxProps;

export const Stack: React.FC<StackProps> = ({
    isInline = false,
    isReversed = false,
    direction,
    align,
    stretch = 4,
    justify,
    children,
    ...rest
}) => {
    const _children = getValidChildren(children);

    const childrenCount = Children.count(_children);

    direction = direction || isInline ? (isReversed ? "row-reverse" : "row") : isReversed ? "column-reverse" : "column";
    isInline = direction.startsWith("row");

    return (
        <Flex align={align} direction={direction} justify={justify} {...rest}>
            {Children.map(_children, (child, index) => {
                const isLastChild = index + 1 === childrenCount;
                const _spacing = isLastChild ? null : stretch;

                const spacingStyles = isInline
                    ? { [isReversed ? "ml" : "mr"]: _spacing }
                    : { [isReversed ? "mt" : "mb"]: _spacing };

                return cloneElement(child as any, spacingStyles);
            })}
        </Flex>
    );
};
