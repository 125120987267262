import React from "react";
import { IoArrowBackCircle } from "@react-icons/all-files/io5/IoArrowBackCircle";

import { useTheme } from "ThemeProvider";
import { useModalContext } from "../Modal";
import { CloseButtonProps, Box, Flex, Text } from "Atoms";

export const ModalBackBtn: React.FC<{ title?: string } & CloseButtonProps> = ({ title = "", ...props }) => {
    const { onClose } = useModalContext();
    const { colors } = useTheme();
    return (
        <Flex onClick={onClose} position="absolute" top="8px" left="12px" alignItems={"center"} {...props}>
            <Box as={IoArrowBackCircle} w="40px" h="40px" mr={6} color={colors.expressPrimary} />
            <Text fontSize={"xl"} fontWeight="400">
                {title}
            </Text>
        </Flex>
    );
};
