import React from "react";
import { useController } from "react-hook-form";

import { InputProps, RHCommonProps, Textarea, RHWrapper } from "Atoms";

type RHTextAreaInputProps = {
    textAlign?: "right" | "left";
} & RHCommonProps &
    InputProps;

export const RHTextAreaInput: React.FC<RHTextAreaInputProps> = ({
    name,
    control,
    formLabel,
    isFullWidth = true,
    helperText,
    isMandatory = false,
    resize = "none",
    wrapperProps,
    ...rest
}) => {
    const {
        field: { onChange, value, ref }
    } = useController({
        name,
        control
    });

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={formLabel}
            helperText={helperText}
            wrapperProps={wrapperProps}
            isMandatory={isMandatory}
        >
            <Textarea
                {...rest}
                ref={ref}
                mb={0}
                rounded="lg"
                resize={resize}
                width={isFullWidth ? "100%" : "auto"}
                onChange={onChange}
                value={value ?? ""}
            />
        </RHWrapper>
    );
};
