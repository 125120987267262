import React from "react";
import { Control, useWatch } from "react-hook-form";

import { useLanguage } from "Providers/languageProvider/LanguageProvider";
import { CustomerType, EatingPreference, FoodOptions } from "Types";
import { BaseBoxProps, Stack } from "../";
import { RHCheckBoxInput, RHFormInput } from "./";

type Props = {
    control: Control<any>;
    foodOptions: FoodOptions;
} & BaseBoxProps;

export const RHInvoicePaymentInformation: React.FC<Props> = ({ control, foodOptions, ...rest }) => {
    const { translate } = useLanguage();

    // Watch for changes in the "shouldUseSameInvoiceAddress" field
    const shouldUseSameInvoiceAddress = useWatch({
        control,
        name: "invoiceData.invoiceAddress.shouldUseSameInvoiceAddress"
    });

    const isCateringDelivery = foodOptions.eatingPreference === EatingPreference.CATERING_DELIVERY;
    const isOrganization = foodOptions.deliveryInformation?.customerType === CustomerType.Organization;
    const shouldShowSameAsAbove = isCateringDelivery && isOrganization;

    // Show address, post code, and city fields if the user is an organization and should not use the same invoice address
    const shouldShowInvoiceAddressFields = isOrganization && !shouldUseSameInvoiceAddress;

    return (
        <Stack stretch={6} {...rest}>
            <RHFormInput
                control={control}
                name="invoiceData.organisationNumber"
                placeholder={translate("organizationNumber")}
                inputMode="numeric"
                pattern="[0-9]*(.[0-9]+)?"
                isMandatory
                isFullWidth
            />
            <RHFormInput
                control={control}
                name="invoiceData.invoiceAddress.name"
                placeholder={translate("companyName")}
                isMandatory
                isFullWidth
            />
            {shouldShowSameAsAbove && (
                <RHCheckBoxInput control={control} name="invoiceData.invoiceAddress.shouldUseSameInvoiceAddress">
                    {translate("useSameAsAbove")}
                </RHCheckBoxInput>
            )}
            {shouldShowInvoiceAddressFields && (
                <>
                    <RHFormInput
                        control={control}
                        name="invoiceData.invoiceAddress.addressLine"
                        placeholder={translate("address")}
                        isMandatory
                        isFullWidth
                    />
                    <RHFormInput
                        control={control}
                        name="invoiceData.invoiceAddress.postCode"
                        placeholder={translate("postCode")}
                        inputMode="numeric"
                        pattern="[0-9]*(.[0-9]+)?"
                        isMandatory
                        isFullWidth
                    />
                    <RHFormInput
                        control={control}
                        name="invoiceData.invoiceAddress.city"
                        placeholder={translate("district")}
                        isMandatory
                        isFullWidth
                    />
                </>
            )}
        </Stack>
    );
};
