import React from "react";
import { useController } from "react-hook-form";

import { Checkbox, FormLabel, Flex, BaseBoxProps, RHCommonProps, RHWrapper } from "Atoms";

export type RHCheckBoxInputProps = {
    textAlign?: "right" | "left";
    isDisabled?: boolean;
    tooltipTextInfo?: string;
} & RHCommonProps &
    Omit<BaseBoxProps, "size">;

export const RHCheckBoxInput: React.FC<RHCheckBoxInputProps> = ({
    formLabel,
    helperText,
    isMandatory = false,
    textAlign = "right",
    control,
    name,
    wrapperProps,
    isDisabled = false,
    tooltipTextInfo,
    ...rest
}) => {
    const {
        field: { onChange, value, ref }
    } = useController({
        name,
        control
    });

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={undefined}
            helperText={helperText}
            wrapperProps={wrapperProps}
            isMandatory={isMandatory}
            onlyShowErrorWhenTouched={false}
        >
            <Flex direction={textAlign === "left" ? "row-reverse" : "row"}>
                {formLabel && <FormLabel>{formLabel}</FormLabel>}

                <Checkbox
                    ref={ref}
                    onChange={onChange}
                    isChecked={!!value}
                    value={value ?? false}
                    isDisabled={isDisabled}
                    tooltipTextInfo={tooltipTextInfo}
                    {...rest}
                    mb={0}
                />
            </Flex>
        </RHWrapper>
    );
};
