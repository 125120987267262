import React from "react";
import { useController } from "react-hook-form";

import { BaseBoxProps, RHCommonProps, Switch, SwitchProps, RHWrapper } from "Atoms";

type RHSwitchInputProps = { isInline?: boolean } & RHCommonProps & SwitchProps & BaseBoxProps;

export const RHSwitchInput: React.FC<RHSwitchInputProps> = ({
    name,
    control,
    placeholder,
    formLabel,
    helperText,
    isMandatory = false,
    isFullWidth = true,
    isInline = true,
    children,
    wrapperProps,
    ...rest
}) => {
    const {
        field: { onChange, onBlur, value, ref }
    } = useController({
        name,
        control,
        defaultValue: ""
    });

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={formLabel}
            helperText={helperText}
            wrapperProps={{ flexDirection: isInline ? "row" : "column", ...wrapperProps }}
            isMandatory={isMandatory}
        >
            <Switch
                size="lg"
                rounded="lg"
                ref={ref}
                onChange={onChange} // send value to hook form
                onBlur={onBlur} // notify when input is touched/blur
                isChecked={value} // input value
                name={name} // send down the input name
                //@ts-ignore
                placeholder={placeholder}
                {...rest}
                mb={0}
            >
                {children}
            </Switch>
        </RHWrapper>
    );
};
