import React, { forwardRef } from "react";

import { InputProps, Input } from "../Input/Input";

export type ITextarea = InputProps<HTMLTextAreaElement> & { fullWidth?: boolean };

export const Textarea: React.FC<ITextarea> = forwardRef(({ color, fullWidth = false, ...props }, ref) => {
    return (
        <Input
            ref={ref}
            as="textarea"
            minHeight="80px"
            paddingY="8px"
            lineHeight="short"
            fullWidth={fullWidth}
            {...props}
        />
    );
});
