import { StylesConfig } from "react-select";

import { useTheme } from "ThemeProvider";

export const useRHSelectInputStyles = () => {
    const theme = useTheme();

    const styles: StylesConfig<any, any> = {
        menuList: (base, props) => ({
            ...base,
            borderRadius: theme.radii["lg"]
        }),
        menu: (base, props) => ({
            ...base,
            boxShadow: theme.shadows["lg"]
        }),
        control: (provided, state) => ({
            ...provided,
            boxShadow: "none",
            border: "1px",
            borderStyle: "solid !important",
            transition: "all 0.25s",
            borderColor: state.isFocused
                ? `${theme.colors.blue["500"]} !important`
                : `${theme.colors.gray["200"]} !important`,
            height: "auto",
            paddingLeft: theme.sizes["4"],
            paddingRight: theme.sizes["4"],
            borderRadius: theme.radii["lg"],
            ...(state.isFocused && {
                borderColor: `${theme.colors.blue["500"]} !important`,
                boxShadow: `0 0 0 1px ${theme.colors.blue["500"]}`,
                zIndex: 1
            }),
            "&:hover": {
                cursor: "pointer",
                borderColor: state.isFocused ? theme.colors.blue["500"] : "gray"
            }
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: 0,
            margin: 0,
            fontSize: theme.fontSizes["lg"],
            marginRight: 6,
            paddingTop: "0.4rem",
            paddingBottom: "0.4rem"
        }),
        multiValue: (base, state) => ({
            ...base,
            borderRadius: theme.radii["lg"],
            backgroundColor: state.data.isFixed ? `${theme.colors.gray["500"]}` : `${theme.colors.gray["300"]}`
        }),
        multiValueLabel: (base, state) => ({
            ...base,
            display: "flex",
            alignItems: "center",
            marginLeft: !state.data.isFixed ? 6 : 3,
            marginRight: state.data.isFixed ? 6 : 0
        }),
        option: (provided, state) => ({
            ...provided,

            ":first-of-type": {
                borderRadius: `${theme.radii["lg"]} ${theme.radii["lg"]} 0px 0px`
            },
            ":last-child": {
                borderRadius: `0px 0px ${theme.radii["lg"]} ${theme.radii["lg"]}`
            },
            ":hover": {
                cursor: state.isDisabled ? "not-allowed" : "pointer"
            },
            backgroundColor: state.isDisabled
                ? "undefined"
                : state.isSelected
                    ? theme.colors.blue["500"]
                    : state.isFocused
                        ? theme.colors.blue["200"]
                        : undefined
        }),
        multiValueRemove: (base, state) => ({
            ...base,
            backgroundColor: theme.colors.gray["300"],
            borderRadius: theme.radii["lg"],
            ":hover": {
                backgroundColor: theme.colors.gray["400"],
                color: theme.colors.gray["700"]
            }
        }),
        indicatorSeparator: (base, state) => ({
            ...base,
            backgroundColor: "none"
        }),
        clearIndicator: (base, state) => ({
            ...base,
            color: theme.colors.gray["900"],
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: 3
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            color: theme.colors.gray["900"]
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 })
    };

    return styles;
};
