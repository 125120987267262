import React, { useRef } from "react";
import ReactSelect from "react-select";

type Option = {
    label: string;
    value: string;
};

type Props = {
    options: Option[];
    value: Option[];
    setValue: any;
    isMulti?: boolean;
    width?: string;
    disableSelectAll?: boolean;
    selectAllText?: string;
};

export const MultiSelect: React.FC<Props> = ({
    options,
    value,
    setValue,
    isMulti = true,
    width = "17vw",
    disableSelectAll = false,
    selectAllText = "Alla enheter"
}) => {
    const valueRef = useRef(value);
    valueRef.current = value;
    const selectAllOption = {
        value: "<SELECT_ALL>",
        label: selectAllText
    };
    const SELECT_OPTION = "select-option";
    const DESELECT_OPTION = "deselect-option";
    const REMOVE_VALUE = "remove-value";

    let getOptions = options;
    if (!disableSelectAll) {
        getOptions = [selectAllOption, ...getOptions];
    }
    const isSelectAllSelected = () => valueRef.current?.length === options.length;
    const getValue = () => (isSelectAllSelected() ? [selectAllOption] : value);

    const isOptionSelected = (option: any) => {
        const current = !isMulti && valueRef.current.length > 2 ? [valueRef.current[0]] : valueRef.current;
        if (isMulti) {
            return current.some(({ value }) => value === option.value) || isSelectAllSelected();
        } else {
            if (valueRef.current.length !== options.length) {
                return current.some(({ value }) => value === option.value);
            } else return [selectAllOption].some(({ value }) => value === option.value);
        }
    };

    const onChange = (newValue: any, actionMeta: any) => {
        const { action, option, removedValue } = actionMeta;
        if (isMulti) {
            if (action === SELECT_OPTION && option.value === selectAllOption.value) {
                setValue(options, actionMeta);
            } else if (
                (action === DESELECT_OPTION && option.value === selectAllOption.value) ||
                (action === REMOVE_VALUE && removedValue.value === selectAllOption.value)
            ) {
                setValue([], actionMeta);
            } else if (actionMeta.action === DESELECT_OPTION && isSelectAllSelected()) {
                setValue(
                    options.filter(({ value }) => value !== option.value),
                    actionMeta
                );
            } else {
                setValue(newValue || [], actionMeta);
            }
        } else {
            if (newValue.value === "<SELECT_ALL>") {
                setValue(options, actionMeta);
            } else {
                setValue([newValue], actionMeta);
            }
        }
    };

    return (
        <>
            {/** @ts-ignore */}
            <ReactSelect
                isOptionSelected={isOptionSelected}
                options={getOptions}
                value={getValue()}
                onChange={onChange}
                hideSelectedOptions={false}
                closeMenuOnSelect={!isMulti}
                isMulti={isMulti}
                styles={{
                    container: base => ({
                        ...base,
                        width: isMulti ? "auto" : width
                    })
                }}
            />
        </>
    );
};
