import React, { useEffect } from "react";
import { usePresence } from "framer-motion";
import FocusLock from "react-focus-lock";
import { RemoveScroll } from "react-remove-scroll";

import { useModalContext } from "../Modal";
import { useModalManager } from "../modalManager";

type ModalFocusScopeProps = {
    children: React.ReactElement;
};

export const ModalFocusScope = ({ children }: ModalFocusScopeProps) => {
    const { removeScrollBar, allowPinchZoom, blockScrollOnMount, dialogRef, trapFocus, open } = useModalContext();

    const [isPresent, safeToRemove] = usePresence();

    useEffect(() => {
        if (!isPresent && safeToRemove) {
            setTimeout(safeToRemove);
        }
    }, [isPresent, safeToRemove]);

    const index = useModalManager(dialogRef, open);

    return (
        <FocusLock disabled={!trapFocus} onActivation={() => dialogRef.current}>
            <RemoveScroll
                removeScrollBar={removeScrollBar}
                allowPinchZoom={allowPinchZoom}
                enabled={(index === 1 || index === 2) && blockScrollOnMount}
                forwardProps
            >
                {children}
            </RemoveScroll>
        </FocusLock>
    );
};
