import React from "react";
import styled, { keyframes } from "styled-components";

import { Box, BaseBoxProps } from "../Box/Box";
import { colors } from "../../theme/colors";

const glow = (colorStart: string, colorEnd: string) => keyframes`
    from {
    border-color: ${colorStart};
    background: ${colorStart};
    }
    to {
    border-color: ${colorEnd};
    background: ${colorEnd};
    }
`;

type IStyledSkeleton = {
    colorStart: string;
    colorEnd: string;
    speed: number;
} & BaseBoxProps;

type ISkeleton = {
    speed?: number;
} & BaseBoxProps;

const StyledSkeleton = styled(Box)<IStyledSkeleton>`
    border-color: ${props => props.colorStart} !important;
    box-shadow: none !important;
    // do not !important this for Firefox support
    background: ${props => props.colorStart};
    // Prevent background color from extending to the border and overlappping
    background-clip: padding-box !important;
    cursor: default;
    // Transparent text will occupy space but be invisible to the user
    color: transparent !important;
    animation: ${props => props.speed}s linear infinite alternate ${props => glow(props.colorStart, props.colorEnd)};
    pointer-events: none;
    user-select: none;
    // Make pseudo-elements (CSS icons) and children invisible
    &::before,
    &::after,
    * {
        visibility: hidden !important;
    }
`;

export const Skeleton: React.FC<ISkeleton> = props => {
    const { speed = 1, ...rest } = props;

    const colorStart = colors.gray["100"],
        colorEnd = colors.gray["400"];

    return <StyledSkeleton borderRadius="3px" colorStart={colorStart} colorEnd={colorEnd} speed={speed} {...rest} />;
};
