import React, {
    Children,
    cloneElement,
    createContext,
    forwardRef,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import { Placement } from "popper.js";

import { Box, Popper, PopperProps, CloseButton, CloseButtonProps } from "Atoms";
import { usePrev } from "CoreHooks";
import { BaseBoxProps } from "../Box/Box";
import { wrapEvent } from "EventUtils";

interface IPopupContext {
    popupRef: React.RefObject<HTMLElement>;
    placement: Placement;
    referenceRef: React.RefObject<HTMLElement> | undefined;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    trigger: "hover" | "click";
    isOpen: boolean;
    onBlur: React.FocusEventHandler<HTMLElement>;
    closeOnEsc: boolean;
    initialFocusRef: any;
    isHoveringRef: React.RefObject<boolean>;
    usePortal: boolean;
}

const PopupContext = createContext<IPopupContext | null>(null);
const usePopupContext = () => {
    const ctx = useContext(PopupContext);
    if (ctx == null) {
        throw Error("usePopupContext must be used within <Popup/>");
    }
    return ctx;
};

type PopupTriggerProps = {
    children: React.ReactElement<{}>;
};

const PopupTrigger: React.FC<PopupTriggerProps> = ({ children }) => {
    const { referenceRef, onToggle, trigger, onOpen, isOpen, onClose, isHoveringRef } = usePopupContext();

    const child = Children.only(children);
    let eventHandlers = {};

    if (trigger === "click") {
        eventHandlers = {
            //@ts-ignore //TODO: Not typescripted properly
            onClick: wrapEvent(onToggle, child.props.onClick)
        };
    }

    if (trigger === "hover") {
        eventHandlers = {
            //@ts-ignore //TODO: Not typescripted properly
            onFocus: wrapEvent(onOpen, child.props.onFocus),
            onKeyDown: wrapEvent((event: React.KeyboardEvent<HTMLImageElement>) => {
                if (event.key === "Escape") {
                    setTimeout(onClose, 300);
                }
                //@ts-ignore //TODO: Not typescripted properly
            }, child.props.onKeyDown),
            //@ts-ignore //TODO: Not typescripted properly
            onBlur: wrapEvent(onClose, child.props.onBlur),
            onMouseEnter: wrapEvent(() => {
                //@ts-ignore
                isHoveringRef.current = true;
                setTimeout(onOpen, 300);
                //@ts-ignore //TODO: Not typescripted properly
            }, child.props.onMouseEnter),
            onMouseLeave: wrapEvent(() => {
                //@ts-ignore
                isHoveringRef.current = false;
                setTimeout(() => {
                    if (isHoveringRef.current === false) {
                        onClose();
                    }
                }, 300);
                //@ts-ignore //TODO: Not typescripted properly
            }, child.props.onMouseLeave)
        };
    }

    //need to take referenceRef from the context
    return cloneElement(child, {
        //@ts-ignore //TODO: Not typescripted properly
        ref: referenceRef,
        ...eventHandlers
    });
};

interface IPopupContent {
    onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
    onBlur?: React.FocusEventHandler<HTMLElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLElement>;
    onMouseEnter?: React.MouseEventHandler<HTMLElement>;
    onFocus?: React.FocusEventHandler<HTMLElement>;
    gutter?: number;
}
type PopupContentProps = IPopupContent & PopperProps;

const PopupContent = ({
    onKeyDown,
    onBlur: onBlurProp,
    onMouseLeave,
    onMouseEnter,
    onFocus,
    gutter = 4,
    ...props
}: PopupContentProps) => {
    const {
        popupRef,
        referenceRef,
        placement,
        isOpen,
        onBlur,
        closeOnEsc,
        onClose,
        isHoveringRef,
        trigger,
        usePortal
    } = usePopupContext();

    let eventHandlers = {};
    let roleProps = {};

    if (trigger === "click") {
        eventHandlers = {
            onBlur: wrapEvent(onBlur, onBlurProp)
        };

        roleProps = {
            role: "dialog",
            "aria-modal": "false"
        };
    }

    if (trigger === "hover") {
        eventHandlers = {
            onMouseEnter: wrapEvent(() => {
                //@ts-ignore
                isHoveringRef.current = true;
            }, onMouseEnter),
            onMouseLeave: wrapEvent(() => {
                //@ts-ignore
                isHoveringRef.current = false;
                setTimeout(onClose, 300);
            }, onMouseLeave)
        };

        roleProps = {
            role: "tooltip"
        };
    }

    eventHandlers = {
        ...eventHandlers,
        onKeyDown: wrapEvent((event: React.KeyboardEvent<HTMLImageElement>) => {
            if (event.key === "Escape" && closeOnEsc) {
                onClose && onClose();
            }
        }, onKeyDown)
    };

    return (
        <Popper
            as="section"
            usePortal={usePortal}
            isOpen={isOpen}
            placement={placement}
            anchorEl={referenceRef ? referenceRef.current : null}
            ref={popupRef}
            background="white"
            tabIndex={-1}
            borderWidth="1px"
            borderColor="gray.300"
            borderStyle="solid"
            position="relative"
            display="flex"
            flexDirection="column"
            rounded="md"
            boxShadow="sm"
            maxWidth="xs"
            modifiers={{ offset: { enabled: true, offset: `0, ${gutter}` } }}
            _focus={{ outline: 0, boxShadow: "outline" }}
            {...roleProps}
            {...eventHandlers}
            {...props}
        />
    );
};

type InternalState = { isOpen?: boolean; onClose?: () => void };

type PopupChildren =
    | {
          children: React.ReactNode;
      }
    | { children: (props: InternalState) => React.ReactNode };

interface IPopup {
    isOpen?: boolean;
    defaultIsOpen?: boolean;
    initialFocusRef?: React.RefObject<HTMLElement>;
    trigger?: "hover" | "click";
    returnFocusOnClose?: boolean;
    gutter?: number;
    placement?: Placement;
    closeOnBlur?: boolean;
    closeOnEsc?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    usePortal?: boolean;
}

type PopupProps = IPopup & PopupChildren;

const Popup: React.FC<PopupProps> = ({
    isOpen: isOpenProp,
    initialFocusRef,
    defaultIsOpen,
    usePortal = false,
    returnFocusOnClose = true,
    trigger = "click",
    placement = "top",
    children,
    closeOnBlur = true,
    closeOnEsc = true,
    onOpen: onOpenProp,
    onClose: onCloseProp
}) => {
    const [isOpen, setIsOpen] = useState(defaultIsOpen || false);
    const { current: isControlled } = useRef(isOpenProp != null);

    const isHoveringRef = useRef();

    const referenceRef = useRef();
    const popupRef = useRef();

    const _isOpen = isControlled ? isOpenProp : isOpen;

    const onToggle = () => {
        if (!isControlled) {
            setIsOpen(!_isOpen);
        }

        if (!_isOpen === true) {
            onOpenProp && onOpenProp();
        } else {
            onCloseProp && onCloseProp();
        }
    };

    const onOpen = () => {
        if (!isControlled) {
            setIsOpen(true);
        }

        if (onOpenProp) {
            onOpenProp();
        }
    };

    const onClose = () => {
        if (!isControlled) {
            setIsOpen(false);
        }

        if (onCloseProp) {
            onCloseProp();
        }
    };

    const handleBlur = (event: any) => {
        if (
            _isOpen &&
            closeOnBlur &&
            popupRef.current &&
            referenceRef.current &&
            //@ts-ignore
            !popupRef.current.contains(event.relatedTarget) &&
            //@ts-ignore
            !referenceRef.current.contains(event.relatedTarget)
        ) {
            onClose();
        }
    };

    const prevIsOpen = usePrev(_isOpen);

    useEffect(() => {
        if (_isOpen && trigger === "click") {
            requestAnimationFrame(() => {
                if (initialFocusRef && initialFocusRef.current) {
                    initialFocusRef.current.focus();
                } else {
                    if (popupRef.current) {
                        //@ts-ignore
                        popupRef.current.focus();
                    }
                }
            });
        }

        if (!_isOpen && prevIsOpen && trigger === "click" && returnFocusOnClose) {
            if (referenceRef.current) {
                //@ts-ignore
                referenceRef.current.focus();
            }
        }
    }, [_isOpen, popupRef, initialFocusRef, trigger, referenceRef, prevIsOpen, returnFocusOnClose]);

    const context = {
        popupRef,
        placement,
        referenceRef,
        onOpen,
        onClose,
        onToggle,
        trigger,
        isOpen: !!_isOpen,
        onBlur: handleBlur,
        closeOnEsc,
        initialFocusRef,
        isHoveringRef,
        usePortal
    } as any;

    return (
        <PopupContext.Provider value={context}>
            {typeof children === "function" ? children({ isOpen: _isOpen, onClose }) : children}
        </PopupContext.Provider>
    );
};

const PopupHeader = (props: BaseBoxProps) => {
    return <Box as="header" px={3} py={2} borderBottomWidth="1px" {...props} />;
};

const PopupActions = (props: BaseBoxProps) => <Box as="footer" px={3} py={2} borderTopWidth="1px" {...props} />;

const PopupBody = (props: BaseBoxProps) => {
    return <Box flex="1" px={3} py={2} {...props} />;
};

const PopupCloseButton = ({ onClick, ...props }: CloseButtonProps) => {
    const { onClose } = usePopupContext();
    return (
        <CloseButton
            size="lg"
            onClick={wrapEvent(onClose, onClick)}
            position="absolute"
            rounded="md"
            top={1}
            right={2}
            p={2}
            {...props}
        />
    );
};

export { PopupHeader, PopupActions, PopupBody, Popup, PopupTrigger, PopupContent, PopupCloseButton };
