import React from "react";

import { Box, BaseBoxProps } from "../Box/Box";

export const headerSizes = {
    "4xl": ["6xl", null, "5rem"],
    "3xl": ["5xl", null, "6xl"],
    "2xl": ["4xl", null, "5xl"],
    xl: ["3xl", null, "4xl"],
    lg: ["xl", null, "2xl"],
    md: "xl",
    sm: "md",
    xs: "sm"
};

export type HeaderSizes = "4xl" | "3xl" | "2xl" | "xl" | "lg" | "md" | "sm" | "xs";

export type HeaderProps = {
    size?: HeaderSizes;
    color?: any;
} & BaseBoxProps;

export const Header: React.FC<HeaderProps> = React.forwardRef(({ size = "xl", as, color, ...props }, ref) => {
    // @ts-ignore
    const fontSize = headerSizes[size];
    const headerElement = as || "h2";
    return (
        <Box
            ref={ref}
            as={headerElement}
            color={color}
            fontSize={fontSize}
            lineHeight="shorter"
            fontWeight="bold"
            fontFamily="heading"
            {...props}
        />
    );
});
