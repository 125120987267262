import React, { forwardRef } from "react";
import styled from "styled-components";

import { Box, BaseBoxProps } from "Atoms";
import { useTheme } from "ThemeProvider";

const Svg = styled(Box)`
    flex-shrink: 0;
    backface-visibility: hidden;
    &:not(:root) {
        overflow: hidden;
    }
    transform: translate(0, 0) !important;
`;

interface ISvgIcon {
    size?: string;
    name?: string;
    color?: string;
    role?: "presentation" | "img";
    focusable?: boolean;
}

export type SvgIconProps = ISvgIcon & Omit<BaseBoxProps, "size">;

export const SvgIcon: React.FC<SvgIconProps> = forwardRef(
    ({ size = "1em", name, color = "currentColor", role = "presentation", focusable = false, ...rest }, ref) => {
        const { icons: iconPaths } = useTheme();

        // Fallback in case you pass the wrong name
        const iconFallback = iconPaths["question-outline"];

        const path = iconPaths[name ?? ""] == null ? iconFallback.path : iconPaths[name as string].path;

        const viewBox =
            (iconPaths[name ?? ""] == null ? iconFallback.viewBox : iconPaths[name as string].viewBox) || "0 0 24 24";

        return (
            <Svg
                ref={ref}
                as="svg"
                size={size}
                color={color}
                display="inline-block"
                verticalAlign="middle"
                viewBox={viewBox}
                focusable={focusable}
                role={role}
                {...rest}
            >
                {path}
            </Svg>
        );
    }
);
