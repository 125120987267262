import React, { Children, cloneElement, isValidElement } from "react";

import { useKeypad } from "../KeyPad";
import { Flex, FlexProps } from "Atoms";

type KeyPadButtonsProps = {
    buttonsClassName?: string;
} & FlexProps;

export const KeypadButtons: React.FC<KeyPadButtonsProps> = ({
    children,
    buttonsClassName,
    height = "25vh",
    ...rest
}) => {
    const { disabledButtons, onButtonClick, isOpen } = useKeypad();

    if (!isOpen) {
        return null;
    }

    const clones = Children.map(children, child => {
        if (!isValidElement(child)) {
            return;
        }

        const { value, onClick, ...props } = child.props;

        return cloneElement(child, {
            isDisabled: disabledButtons && disabledButtons.includes(value),
            onClick: () => onButtonClick(value),
            ...props
        });
    });

    return (
        <Flex wrap="wrap" height={height} zIndex={1} className={buttonsClassName} {...rest}>
            {clones}
        </Flex>
    );
};
