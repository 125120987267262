import { IMAGE_FORMAT } from "Types";

export enum SIMPLE_CHOICE {
    YES = "Yes",
    NO = "No"
}

export enum SHAPE {
    RECTANGLE = "Rectangle",
    CIRCLE = "Circle"
}

export type FileNameAndExtension = {
    fileName: string;
    fileExtension?: IMAGE_FORMAT | null;
};

export type ImageNaturalAndRenderedSize = {
    renderedWidth: number;
    renderedHeight: number;
    naturalWidth: number;
    naturalHeight: number;
    scaleX: number;
    scaleY: number;
};

export type ScaledCrop = Pick<ImageNaturalAndRenderedSize, "naturalHeight" | "naturalWidth">;
