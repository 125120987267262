import React from "react";
import styled from "styled-components";
import * as Icons from "../../assets/react-icons";

export const actualSizes = {
    tiny: "1em",
    xsmall: "1.5em",
    small: "2.2em",
    medium: "2.4em",
    large: "2.6em",
    huge: "2.8em",
    massive: "6em"
};

interface IconProps extends React.HTMLAttributes<HTMLElement> {
    name: string;
    size: "tiny" | "small" | "medium" | "large" | "huge" | "massive";
    width: string;
    height: string;
    color: string;
}

const iconElements: any = {};

Object.entries(Icons).map(([key, value]: [string, any]) =>
    Object.assign(iconElements, {
        [key]: value.call()
    })
);

export const StyledIcon = styled.div<Partial<IconProps>>`
    display: inline-block;
    vertical-align: text-top;
    padding: 0;
    margin: 0 2px;
    flex: none;
    width: auto;
    height: auto;
    & svg {
        vertical-align: baseline;
        width: ${props => (props.width ? props.width : actualSizes[props.size || "small"])};
        height: ${props => (props.height ? props.height : actualSizes[props.size || "small"])};
        fill: ${props => props.color};
    }
`;

export const Icon: React.FC<Partial<IconProps>> = ({ name = "", ...rest }) => {
    return <StyledIcon {...rest}>{iconElements[name]}</StyledIcon>;
};
