import React from "react";

import { PseudoBox, PseudoBoxProps } from "../PseudoBox/PseudoBox";
import { LinkType } from "Types";

type ILink = {
    isExternal?: boolean;
    onClick?: () => void;
    variantColor?: string;
    to?: string;
};

type Props = PseudoBoxProps & ILink & React.HTMLProps<HTMLAnchorElement>;

const baseStyles = {
    textDecoration: "none",
    transition: " 0.2s",
    outline: "none",
    cursor: "pointer"
};

export const Link: React.FC<Props> = ({ isExternal, onClick, variantColor = "gray", ...rest }) => {
    return (
        <PseudoBox
            as={"a" as LinkType}
            color={rest.color ? rest.color : `${variantColor}.900`}
            onClick={() => onClick && onClick()}
            target={isExternal ? "_blank" : undefined}
            _hover={{
                textDecoration: rest.textDecoration ? rest.textDecoration : "underline",
                color: rest.color ? rest.color : `${variantColor}.900`
            }}
            _focus={{
                boxShadow: "outline"
            }}
            {...baseStyles}
            {...rest}
        />
    );
};
