import React from "react";
import { useController } from "react-hook-form";
import { WidthProps } from "styled-system";

import { Input, BaseBoxProps, InputProps, RHCommonProps, RHWrapper } from "Atoms";

export type RHNumericInputProps = {
    formLabelWidth?: WidthProps["width"];
    textAlign?: "right" | "left";
} & RHCommonProps &
    InputProps &
    BaseBoxProps;

export const RHNumericInput: React.FC<RHNumericInputProps> = ({
    formLabel,
    formLabelWidth,
    helperText,
    isMandatory = false,
    textAlign = "right",
    control,
    name,
    children,
    min,
    defaultValue,
    wrapperProps,
    ...rest
}) => {
    const {
        field: { onChange, onBlur, ref, value }
    } = useController({
        name,
        control
    });

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={formLabel}
            helperText={helperText}
            wrapperProps={wrapperProps}
            isMandatory={isMandatory}
        >
            <Input
                type="number"
                size="lg"
                rounded="lg"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(+e.target.value)}
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => +e.target.value === 0 && e?.target?.select()}
                onBlur={onBlur}
                ref={ref}
                defaultValue={defaultValue}
                min={min}
                value={value ?? 0}
                {...rest}
                mb={0}
            />
        </RHWrapper>
    );
};
