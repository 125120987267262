import React from "react";
import { useController } from "react-hook-form";

import {
    Box,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Fade,
    RHCommonProps,
    Text,
    BetaLabel,
    Flex,
    ToolTip,
    Icon
} from "Atoms";
import { useLanguage } from "Providers/languageProvider/LanguageProvider";

export type RHWrapperProps = {
    onlyShowLabelWithValue?: boolean;
    /**
     * If `false`, error will show even if component
     * hasn't been touched.
     * @default true
     */
    onlyShowErrorWhenTouched?: boolean;
} & RHCommonProps &
    RHExtendedWrapperProps;

export type RHExtendedWrapperProps = {
    tooltipLabelText?: string;
    showExtendedLabelText?: boolean;
};

export const RHWrapper: React.FC<RHWrapperProps> = ({
    name,
    control,
    isMandatory,
    formLabel,
    helperText,
    children,
    wrapperProps,
    onlyShowLabelWithValue,
    onlyShowErrorWhenTouched = true,
    isBeta = false,
    showExtendedLabelText = false,
    tooltipLabelText
}) => {
    const {
        field: { value },
        fieldState: { isTouched, error }
    } = useController({
        name,
        control
    });
    const { translate } = useLanguage();

    const isInvalid = onlyShowErrorWhenTouched ? !!(isTouched && error?.message) : !!error?.message;

    let shouldShowLabel = true;
    if (onlyShowLabelWithValue) {
        shouldShowLabel = !!value && !!formLabel;
    } else {
        shouldShowLabel = !!formLabel;
    }

    const isValidated = !error?.message && !!value;
    const isNotEmpty = isTouched && value;
    const hasError = !!error?.message;
    const shouldShowMandatory = (isNotEmpty || (isMandatory && !value)) && !hasError && !isValidated;

    return (
        <Box display="flex" flexDirection="column" mb={6} {...wrapperProps}>
            {/* Would be really cool to also use Framer to transition the height/width as this fades and unmounts */}
            <Fade in={shouldShowLabel} unmountOnExit={true}>
                <Flex mb="2">
                    {formLabel && <FormLabel>{formLabel}</FormLabel>}
                    {isBeta && <BetaLabel themeColor="red" shouldShowBetaExplanation={false} />}
                    {!!tooltipLabelText && (
                        <Box justifySelf="center">
                            {!showExtendedLabelText ? (
                                <ToolTip text={tooltipLabelText} placement={"right"} alignItems={"center"}>
                                    <Icon name="BsInfoCircleFill" color="#A0AEC0" size="tiny" />
                                </ToolTip>
                            ) : (
                                <Text as="span" >{` (${tooltipLabelText})`}</Text>
                            )}
                        </Box>
                    )}
                </Flex>
            </Fade>
            {children}
            {isInvalid && <FormErrorMessage data-test="input-form-error">{error?.message}</FormErrorMessage>}
            {shouldShowMandatory && (
                <Text as="span" fontSize="sm" mt={1} color="gray.700">
                    {translate("mandatory")}
                </Text>
            )}
            {helperText && (
                <FormHelperText mt={1} data-test="input-form-helper">
                    {helperText}
                </FormHelperText>
            )}
        </Box>
    );
};
