import React from "react";
import { IoMdGrid } from "@react-icons/all-files/io/IoMdGrid";
import { IoMdList } from "@react-icons/all-files/io/IoMdList";

import { IconButton } from "Atoms";

type Props = {
    isTile: boolean;
    toListMode: () => void;
    toTileMode: () => void;
};

export const TileListIconButton: React.FC<Props> = ({ isTile, toListMode, toTileMode }) => (
    <IconButton icon={isTile ? IoMdGrid : IoMdList} onClick={() => (isTile ? toListMode() : toTileMode())} />
);
