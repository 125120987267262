import React from "react";
import { HTMLMotionProps } from "framer-motion";

import { scaleFadeConfig } from "../../ScaleFade/ScaleFade";
import { BaseBoxProps, MotionSection } from "Atoms";

export type ModalTransitionProps = {
    preset: "scale" | "none";
} & BaseBoxProps &
    HTMLMotionProps<"section">;

const transitions = {
    // TODO: Implement
    slideInBottom: {},
    // TODO: Implement
    slideInRight: {},
    scale: {
        ...scaleFadeConfig,
        custom: { initialScale: 0.95, reverse: true }
    },
    none: {}
};

export const ModalTransition = React.forwardRef((props: ModalTransitionProps, ref: React.Ref<any>) => {
    const { preset, ...rest } = props;
    const motionProps = transitions[preset];

    return <MotionSection ref={ref} {...motionProps} {...rest} />;
});
