import React, { cloneElement, Children, isValidElement } from "react";

import { Box, BaseBoxProps } from "../Box/Box";
import { PseudoBoxProps, PseudoBox } from "../PseudoBox/PseudoBox";

export type NewListProps = { stretch?: any } & BaseBoxProps;
export type NewListItemProps = { stretch?: any } & PseudoBoxProps;

export const NewList: React.FC<NewListProps> = ({ children, stretch, ...rest }) => {
    const childrenCount = Children.count(children);

    return (
        <Box as="ul" {...rest}>
            {Children.map(children, (child, index) => {
                if (!isValidElement(child)) return;

                const isLastChild = index + 1 === childrenCount;
                if (isLastChild) {
                    return child;
                } else {
                    // @ts-ignore
                    return cloneElement(child, { stretch });
                }
            })}
        </Box>
    );
};

export const NewListItem: React.FC<NewListItemProps> = ({ stretch, ...props }) => {
    return <PseudoBox as="li" mb={stretch} {...props} />;
};

export const List = NewList;
export const ListItem = NewListItem;
