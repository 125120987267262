import React from "react";
import { useController } from "react-hook-form";

import { Checkbox, FormLabel, Flex, BaseBoxProps, RHCommonProps, RHWrapper, Link, Box } from "Atoms";
import { useLanguage } from "Providers/languageProvider/LanguageProvider";

export type RHCheckBoxOption = {
    value: string;
    label: string | JSX.Element;
    isDisabled?: boolean;
    isFixed?: boolean;
};

export type RHCheckBoxGroupProps = {
    groupHeader: string;
    isDisabled?: boolean;
    options: RHCheckBoxOption[];
    direction?: "column" | "column-reverse" | "row";
    labelPlacement?: "left" | "right";
    isMulti?: boolean;
    showSelectAndUnselectAll?: boolean;
    selectAndUnselectAllType?: "text" | "checkbox";
    rowMargin?: number;
} & Partial<BaseBoxProps> &
    Omit<RHCommonProps, "formLabel">;

/**
 * Used for grouping a bunch of checkboxes
 * e.g selecting products and passing the id on selection to the form
 * @type {string} Grouped checkboxes for single choice
 * @type {string[]} Grouped checkboxes for multi choice
 * @example if preselected values that are defauly make sure checkbox option has isFixed = true & perhaps isDisabled = true
 * @example You don't need to add default values to form - any is fixed will pre select:
 *  NOTE: that any default values in form will override the default here - however this should not matter if it was already fixed before
 *  NOTE: As for new forms this will be just a new checkbox multi
 *  NOTE: Labels can be JSX elements or strings
 */
export const RHCheckBoxGroupInput: React.FC<RHCheckBoxGroupProps> = ({
    groupHeader,
    helperText,
    control,
    name,
    wrapperProps,
    options,
    isDisabled = false,
    isMandatory = false,
    direction = "column",
    labelPlacement = "right",
    isMulti = true,
    showSelectAndUnselectAll = true,
    selectAndUnselectAllType = "text",
    rowMargin = 0,
    size = "md"
}) => {
    const { translate } = useLanguage();

    /** If options has a isFixed here it will be a defualt value */
    const defaultFixedValues = options.filter(opt => opt.isFixed).map(opt => opt.value);

    const {
        field: { onChange: onChangeInternal, value: checkBoxGroupValues }
    } = useController({
        name,
        control,
        defaultValue: defaultFixedValues
    });

    const placeLabelRightOfCheckbox = labelPlacement === "right";

    const onCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, id: checkBoxValue } = event.target;
        if (checked) {
            if (isMulti) {
                onChangeInternal([...checkBoxGroupValues, checkBoxValue]);
            } else {
                onChangeInternal(checkBoxValue);
            }
        } else {
            if (isMulti) {
                onChangeInternal(checkBoxGroupValues.filter((value: string) => value !== checkBoxValue));
            } else {
                onChangeInternal("");
            }
        }
    };

    const areAllChecked = options
        .filter(opt => !opt.isDisabled || opt.isFixed)
        .every(opt => checkBoxGroupValues.includes(opt.value));

    const onSelectOrUnselectAll = (selectAll: boolean) => {
        if (selectAll) {
            onChangeInternal(options.filter(opt => !opt.isDisabled || opt.isFixed).map(opt => opt.value));
        } else {
            onChangeInternal(options.filter(opt => opt.isFixed).map(opt => opt.value));
        }
    };

    // Perhaps it's also best to check if selectAndUnselectType is defined
    const showSelectAndUnselectOptions = isMulti && showSelectAndUnselectAll;

    // Checkbox specific logic
    //  - If selectAndUnselectAllType is text, we show text links to "select/unselect all"
    //  - formLabel is the same as groupHeader, unless selectAndUnselectAllType is checkbox
    //  - checkboxIndentation is a REM left margin to indent the checkboxes
    const isCheckbox = selectAndUnselectAllType === "checkbox";
    const formLabel = !isCheckbox ? groupHeader : undefined;
    const checkboxIndentation = isCheckbox ? 2 : 0;

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={formLabel}
            helperText={helperText}
            wrapperProps={wrapperProps}
            isMandatory={isMandatory}
            onlyShowErrorWhenTouched={false}
        >
            <Flex direction={direction}>
                {showSelectAndUnselectOptions &&
                    (selectAndUnselectAllType === "text" ? (
                        <Flex mb={rowMargin}>
                            <Link mr={2} onClick={() => onSelectOrUnselectAll(true)}>
                                {translate("selectAll")}
                            </Link>
                            |
                            <Link ml={2} onClick={() => onSelectOrUnselectAll(false)}>
                                {translate("unSelectAll")}
                            </Link>
                        </Flex>
                    ) : (
                        // Checkbox version
                        <Checkbox
                            id={groupHeader}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                onSelectOrUnselectAll(event.target.checked)
                            }
                            isChecked={areAllChecked}
                            value={groupHeader}
                            isDisabled={false}
                            mb={rowMargin}
                            size={size as any}
                        >
                            <Box
                                fontSize="md"
                                fontWeight="bold"
                                textAlign="left"
                                verticalAlign="middle"
                                display="inline-block"
                            >
                                {groupHeader}
                            </Box>
                        </Checkbox>
                    ))}

                {/* This Box is used to indent the checkboxes */}
                <Box ml={checkboxIndentation}>
                    {options.map((opt: RHCheckBoxOption) => {
                        const option = isMulti
                            ? checkBoxGroupValues?.find((val: any) => val === opt.value)
                            : opt.value === checkBoxGroupValues
                            ? opt
                            : null;

                        return (
                            <Flex key={opt.value}>
                                {!placeLabelRightOfCheckbox && <FormLabel size={size}>{opt.label}</FormLabel>}
                                <Checkbox
                                    id={opt.value}
                                    onChange={onCheckBoxChange}
                                    isChecked={!!option}
                                    value={option ?? false}
                                    /** Normal disabled options - if disabled is missing from options isFixed will disable */
                                    isDisabled={opt?.isDisabled || isDisabled || opt.isFixed}
                                    mb={rowMargin}
                                    size={size as any}
                                >
                                    {placeLabelRightOfCheckbox && opt.label}
                                </Checkbox>
                            </Flex>
                        );
                    })}
                </Box>
            </Flex>
        </RHWrapper>
    );
};
