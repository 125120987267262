import React, { useState, useRef, useEffect, createContext, useContext } from "react";

import { useOutsideClick } from "CoreHooks";
import { Box, BaseBoxProps } from "Atoms";
import { StringOrNumber } from "Types";

type KeyPadRenderProps = {
    open: boolean;
    onOpen: any;
    onClose: any;
};

type KeyPadChildren = { children?(props: KeyPadRenderProps): React.ReactNode } | { children?: React.ReactNode };

interface IKeypadContext {
    isOpen?: boolean;
    isDefaultOpen?: boolean;
    onChange: (value: StringOrNumber) => void;
    onClose: () => void;
    onOpen: () => void;
    onButtonClick: (value: StringOrNumber) => void;
    value: StringOrNumber;
    disabledButtons?: StringOrNumber[];
    hideInputWhenClosed?: boolean;
    inputRef: React.RefObject<HTMLInputElement>;
}

type IKeypad = {
    value: StringOrNumber;
    isOpen?: boolean;
    onChange: (value: StringOrNumber) => void;
    isDefaultOpen?: boolean;
    hideInputWhenClosed?: boolean;
    disabledButtons?: any[];
    customHandlers?: {
        [key: string]: Function;
    };
    onToggleKeyPad?: (open: boolean) => void;
    closeOnOutsideClick?: boolean;
    shouldSumValue?: boolean;
} & KeyPadChildren;

export type KeypadProps = KeyPadChildren & Omit<BaseBoxProps, "onChange"> & IKeypad;

export const DEFAULT_KEYPAD_BUTTONS = [
    { value: 1, children: 1 },
    { value: 2, children: 2 },
    { value: 3, children: 3 },
    { value: 4, children: 4 },
    { value: 5, children: 5 },
    { value: 6, children: 6 },
    { value: 7, children: 7 },
    { value: 8, children: 8 },
    { value: 9, children: 9 },
    { value: "", children: "" },
    { value: 0, children: 0 },
    { value: "DEL", children: "DEL" }
];

export const KeyPadContext = createContext<IKeypadContext>(null as any);
export const useKeypad = () => useContext(KeyPadContext);

export const Keypad: React.FC<KeypadProps> = ({
    children,
    customHandlers,
    isOpen,
    value,
    onChange,
    onToggleKeyPad,
    isDefaultOpen,
    closeOnOutsideClick = true,
    hideInputWhenClosed = false,
    disabledButtons,
    shouldSumValue = true,
    ...rest
}) => {
    const [showKeyPad, setShowKeyPad] = useState(() => isDefaultOpen || false);

    const isControlled = isOpen != undefined;

    const _isKeypadOpen = isControlled ? !!isOpen : showKeyPad;

    const onClose = () => {
        !!onToggleKeyPad && onToggleKeyPad(false);
        !isControlled && setShowKeyPad(false);
    };

    const onOpen = () => {
        !!onToggleKeyPad && onToggleKeyPad(true);
        !isControlled && setShowKeyPad(true);
    };

    const inputRef = useRef<HTMLInputElement>(null);
    const keyPadContainerRef = useRef(null);

    useEffect(() => {
        if (_isKeypadOpen && inputRef) {
            inputRef.current?.focus();
        }
    }, [_isKeypadOpen, inputRef]);

    const onButtonClick = (btnValue: StringOrNumber) => {
        if (customHandlers && customHandlers[btnValue]) {
            return customHandlers[btnValue](value);
        }

        if (_isKeypadOpen) {
            const _value = shouldSumValue ? String(value) + String(btnValue) : btnValue;
            onChange(_value);
        }
    };

    useOutsideClick({
        ref: keyPadContainerRef,
        callback: onClose,
        active: closeOnOutsideClick && _isKeypadOpen
    });

    return (
        <KeyPadContext.Provider
            value={{
                isOpen: _isKeypadOpen,
                onClose,
                onOpen,
                value,
                onChange,
                onButtonClick,
                inputRef,
                hideInputWhenClosed,
                disabledButtons
            }}
        >
            <Box ref={keyPadContainerRef} {...rest}>
                {typeof children === "function"
                    ? children({
                          open: _isKeypadOpen,
                          onOpen,
                          onClose
                      })
                    : children}
            </Box>
        </KeyPadContext.Provider>
    );
};
