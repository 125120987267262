/*
 * Portal Component
 *
 * The following code is from the Material UI team.
 * Original source: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Portal/Portal.js
 */

import React, { useState, useRef } from "react";
import { createPortal } from "react-dom";

import { useEffectOnce, useEnhancedEffect } from "CoreHooks";
import { createContext } from "../../utils/createContext";

export interface PortalProps {
    children: React.ReactNode;
    container?: React.ReactInstance | (() => React.ReactInstance | null) | null;
    isDisabled?: boolean;
    onRendered?: () => void;
    appendToParentPortal?: boolean;
}

type PortalContext = HTMLDivElement | null;

const [PortalContextProvider, usePortalContext] = createContext<PortalContext>({
    strict: false,
    name: "PortalContext"
});

const PORTAL_CLASS_NAME = "q-portal";

//@ts-ignore //TODO: Not typescripted properly
export const Portal: React.FC<PortalProps> = props => {
    const { appendToParentPortal = true, children } = props;

    const [tempNode, setTempNode] = useState<HTMLElement | null>(null);
    const portal = useRef<HTMLDivElement | null>(null);

    const [, forceUpdate] = useState({});
    useEffectOnce(() => forceUpdate({}));

    const parentPortal = usePortalContext();

    useEnhancedEffect(() => {
        if (!tempNode) return;

        const doc = tempNode.ownerDocument;
        const host = appendToParentPortal ? parentPortal ?? doc.body : doc.body;

        if (!host) return;

        portal.current = doc.createElement("div");
        portal.current.className = PORTAL_CLASS_NAME;

        host.appendChild(portal.current);
        forceUpdate({});

        const portalNode = portal.current;
        return () => {
            if (host.contains(portalNode)) {
                host.removeChild(portalNode);
            }
        };
    }, [tempNode]);

    return portal.current ? (
        createPortal(<PortalContextProvider value={portal.current}>{children}</PortalContextProvider>, portal.current)
    ) : (
        <span
            ref={el => {
                if (el) setTempNode(el);
            }}
        />
    );
};
