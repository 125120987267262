import React, { forwardRef } from "react";
import { Box, BaseBoxProps } from "../Box/Box";
import { InputBox } from "../InputBox/InputBox";
import { v4 as uuidv4 } from "uuid";

export type SwitchSizes = "sm" | "md" | "lg" | "smalltouch" | "touch";
interface ISwitch {
    id: string;
    name: string;
    value: string;
    themeColor: string;
    size: SwitchSizes;
    isChecked: boolean;
    isDisabled: boolean;
    defaultChecked: boolean;
    onChange: any;
    onBlur: any;
    onFocus: any;
}

export type SwitchProps = Partial<ISwitch> & BaseBoxProps;

const switchSizes = {
    sm: {
        width: "1.375rem",
        height: "0.7333rem"
    },
    md: {
        width: "1.875rem",
        height: "1rem"
    },
    lg: {
        width: "2.875rem",
        height: "1.5333rem"
    },
    smalltouch: {
        width: "4.7rem",
        height: "2.506rem"
    },
    touch: {
        width: "5.875rem",
        height: "3.133rem"
    }
};

export const Switch: React.FC<SwitchProps> = forwardRef(
    (
        {
            name,
            // if id is not specified, it needs to be unique
            id = name || `switch-${uuidv4()}`,
            value,
            defaultChecked,
            isChecked,
            isDisabled,
            onChange,
            onBlur,
            onFocus,
            themeColor = "blue",
            size = "md",
            ...rest
        },
        ref
    ) => {
        const { width, height } = switchSizes[size as SwitchSizes];

        const inputBoxStyles = {
            rounded: "99px",
            justifyContent: "flex-start",
            width,
            height,
            bg: "gray.500",
            p: "2px",
            _checked: {
                bg: `${themeColor}.500`
            },
            _child: {
                transform: `translateX(0)`
            },
            _checkedAndChild: {
                transform: `translateX(calc(${width} - ${height}))`
            },
            _disabled: {
                opacity: 0.5
            }
        };

        return (
            <Box id={id} as="label" display="inline-block" verticalAlign="middle" {...rest}>
                <Box
                    ref={ref}
                    border="0px"
                    clip="rect(0px, 0px, 0px, 0px)"
                    height="1px"
                    width="1px"
                    margin="-1px"
                    padding="0px"
                    overflow="hidden"
                    white-space="nowrap"
                    position="absolute"
                    as="input"
                    type="checkbox"
                    name={name}
                    value={value}
                    defaultChecked={defaultChecked}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    checked={isChecked}
                    disabled={isDisabled}
                />
                <InputBox parentId={id} boxSizing="content-box" transition="all 120ms" {...inputBoxStyles}>
                    <Box bg="white" transition="transform 250ms" rounded="full" size={height} />
                </InputBox>
            </Box>
        );
    }
);
