import React, { forwardRef } from "react";

import { BaseBoxProps, Box } from "Atoms";
import { useModalContext } from "../Modal";
import { ModalFocusScope, ModalTransition } from ".";

export type ModalContentProps = {
    isScrolling?: boolean;
    containerProps?: BaseBoxProps;
} & BaseBoxProps;

export const ModalContent: React.FC<ModalContentProps> = forwardRef(
    ({ color, children, width, maxW, containerProps: rootProps, ...props }, ref) => {
        const { getContentProps, getContentContainerProps, motionPreset, overrideOverflow, size, placement } =
            useModalContext();

        //@ts-ignore
        const { size: _siz, ...contentProps } = getContentProps(props, ref) as any;
        const containerProps = getContentContainerProps(rootProps);

        let wrapperStyles = {};
        let contentStyles = {
            mx: "auto",
            top: "0"
        };

        if (placement === "center") {
            wrapperStyles = {
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            };
        } else if (placement === "bottom") {
            wrapperStyles = {
                top: "13.75rem"
            };
        }

        return (
            <ModalFocusScope>
                <Box
                    {...containerProps}
                    position="fixed"
                    left="0"
                    top="0"
                    width="100%"
                    height="100%"
                    overflow={!overrideOverflow ? "hidden" : "none"} // Tracked back a bug with jumping up modal adding the prop to modal solved it
                    zIndex="modal"
                    tabIndex={-1}
                    {...wrapperStyles}
                >
                    <ModalTransition
                        preset={motionPreset}
                        maxWidth={size}
                        width="100%"
                        position="relative"
                        display="flex"
                        flexDirection="column"
                        zIndex="modal"
                        backgroundColor="white"
                        borderRadius="md"
                        my={16}
                        {...contentProps}
                        {...contentStyles}
                    >
                        {children}
                    </ModalTransition>
                </Box>
            </ModalFocusScope>
        );
    }
);
