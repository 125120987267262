import React from "react";
import { AnimatePresence, HTMLMotionProps, motion, Variants as FramerVariants } from "framer-motion";

import { TransitionDefaults, Variants, withDelay, WithTransitionConfig } from "Utils";

interface ScaleFadeOptions {
    initialScale?: number;
    reverse?: boolean;
}

const variants: Variants<ScaleFadeOptions> = {
    exit: ({ reverse, initialScale, transition, transitionEnd, delay }) => ({
        opacity: 0,
        ...(reverse
            ? { scale: initialScale, transitionEnd: transitionEnd?.exit }
            : { transitionEnd: { scale: initialScale, ...transitionEnd?.exit } }),
        transition: transition?.exit ?? withDelay.exit(TransitionDefaults.exit, delay)
    }),
    enter: ({ transitionEnd, transition, delay }) => ({
        opacity: 1,
        scale: 1,
        transition: transition?.enter ?? withDelay.enter(TransitionDefaults.enter, delay),
        transitionEnd: transitionEnd?.enter
    })
};

export const scaleFadeConfig: HTMLMotionProps<"div"> = {
    initial: "exit",
    animate: "enter",
    exit: "exit",
    variants: variants as FramerVariants
};

export interface ScaleFadeProps extends ScaleFadeOptions, WithTransitionConfig<HTMLMotionProps<"div">> {}

export const ScaleFade = React.forwardRef<HTMLDivElement, ScaleFadeProps>((props, ref) => {
    const {
        unmountOnExit,
        in: isOpen,
        reverse = true,
        initialScale = 0.95,
        className,
        transition,
        transitionEnd,
        delay,
        ...rest
    } = props;

    const show = unmountOnExit ? isOpen && unmountOnExit : true;
    const animate = isOpen || unmountOnExit ? "enter" : "exit";

    const custom = { initialScale, reverse, transition, transitionEnd, delay };

    return (
        <AnimatePresence custom={custom}>
            {show && <motion.div ref={ref} {...scaleFadeConfig} animate={animate} custom={custom} {...rest} />}
        </AnimatePresence>
    );
});
