import React from "react";
import { useController } from "react-hook-form";

import { BaseBoxProps, Input, InputProps, RHCommonProps, RHWrapper, RHExtendedWrapperProps } from "Atoms";

type FormInputProps = {
    onlyShowLabelWithValue?: boolean;
    isUpperCase?: boolean;
} & RHCommonProps &
    InputProps &
    BaseBoxProps &
    RHExtendedWrapperProps;

export const RHFormInput: React.FC<FormInputProps> = ({
    name,
    control,
    placeholder,
    formLabel,
    helperText,
    isMandatory = false,
    isFullWidth = true,
    onlyShowLabelWithValue = false,
    isUpperCase = false,
    isDisabled,
    wrapperProps,
    showExtendedLabelText = false,
    tooltipLabelText,
    ...rest
}) => {
    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { isTouched, error }
    } = useController({
        name,
        control
    });

    const isInvalid = !!(isTouched && error?.message);

    let shouldShowLabel = true;
    if (onlyShowLabelWithValue) {
        shouldShowLabel = !!value && !!formLabel;
    } else {
        shouldShowLabel = !!formLabel;
    }

    const onChangeFormInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(!isUpperCase ? event.target.value : event.target.value.toUpperCase());
    }

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={shouldShowLabel ? formLabel : undefined}
            helperText={helperText}
            wrapperProps={wrapperProps}
            isMandatory={isMandatory}
            tooltipLabelText={tooltipLabelText}
            showExtendedLabelText={showExtendedLabelText}
        >
            <Input
                size="lg"
                rounded="lg"
                onChange={onChangeFormInput} // send value to hook form
                onBlur={onBlur} // notify when input is touched/blur
                value={value ?? ""} // input value
                name={name} // send down the input name
                ref={ref}
                isDisabled={isDisabled}
                isInvalid={isInvalid}
                placeholder={placeholder}
                fullWidth={isFullWidth}
                {...rest}
                mb={0}
            />
        </RHWrapper>
    );
};
