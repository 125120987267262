import React from "react";
import { useController } from "react-hook-form";
import { FiCheck } from "@react-icons/all-files/fi/FiCheck";
import { FiEdit } from "@react-icons/all-files/fi/FiEdit";
import Truncate from "react-truncate";

import {
    Editable,
    EditableInput,
    EditablePreview,
    EditableProps,
    Fade,
    Input,
    RHWrapper,
    RHWrapperProps,
    Stack
} from "Atoms";
import { CloseButton, IconButton, InputGroup, InputRightElement } from "Molecules";

export type RHEditableInputProps = {
    onlyShowLabelWithValue?: boolean;
    isSubmitLoading?: boolean;
    // Function to help transforming the value
    normalize?: (value: string) => void;
    onRemoveCallback?: (value: string) => void;
    onSubmit: (value: string) => void;
    /**
     * Regex to handle validation onChange
     */
    regex?: RegExp;
} & RHWrapperProps &
    Omit<EditableProps, "children" | "onSubmit">;

export const RHEditableInput: React.FC<RHEditableInputProps> = ({
    name,
    control,
    placeholder,
    formLabel,
    helperText,
    isMandatory = false,
    isFullWidth = true,
    onlyShowLabelWithValue = false,
    isSubmitLoading = false,
    wrapperProps,
    normalize,
    onSubmit,
    onRemoveCallback,
    regex,
    onlyShowErrorWhenTouched = true,
    ...rest
}) => {
    const {
        field: { onChange, onBlur, value, ref }
    } = useController({
        name,
        control
    });

    const onRemoveValue = (event: React.MouseEvent<any, MouseEvent>) => {
        event.preventDefault();
        onChange("");
        onRemoveCallback && onRemoveCallback(value);
    };

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={formLabel}
            helperText={helperText}
            wrapperProps={wrapperProps}
            isMandatory={isMandatory}
            onlyShowErrorWhenTouched={onlyShowErrorWhenTouched}
        >
            <Editable
                ref={ref}
                onChange={value => {
                    const shouldPerformValidation = !!regex;
                    if (shouldPerformValidation) {
                        const isValid = regex.test(value);

                        if (!isValid) {
                            // event?.preventDefault();
                            return;
                        }
                    }

                    if (normalize) {
                        onChange(normalize(value));
                    } else {
                        onChange(value);
                    }
                }}
                onBlur={onBlur}
                value={value ?? ""}
                onSubmit={() => {
                    /**
                     * Value from onSubmit callback didn't persist with useController
                     * Therefor we are taking value directly from useForm instead
                     */
                    onSubmit && onSubmit(value);
                }}
                placeholder={placeholder}
                w="full"
                display="flex"
                {...rest}
            >
                {props => {
                    return (
                        <>
                            <EditablePreview
                                display="flex"
                                alignItems="center"
                                py={2}
                                px={4}
                                maxW={["20rem", "30rem", "50rem", "50rem"]}
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                            />
                            <Stack isInline w={props.isEditing ? "full" : undefined} flex="1">
                                <InputGroup w="full">
                                    <Input as={EditableInput} rounded="lg" />
                                    <Fade in={!!value} unmountOnExit>
                                        <InputRightElement>
                                            <CloseButton
                                                onClick={onRemoveValue}
                                                position="relative"
                                                top={undefined}
                                                right={undefined}
                                                bg="white"
                                                _focus={{ boxShadow: "outline" }}
                                            />
                                        </InputRightElement>
                                    </Fade>
                                </InputGroup>

                                {isSubmitLoading || props.isEditing ? (
                                    <IconButton
                                        icon={FiCheck}
                                        onClick={() => props.onSubmit()}
                                        fontSize="2xl"
                                        themeColor="blue"
                                        variant="ghost"
                                        rounded="lg"
                                        type="button"
                                        isLoading={isSubmitLoading}
                                        _focus={{ boxShadow: "outline" }}
                                    />
                                ) : (
                                    <IconButton
                                        icon={FiEdit}
                                        onClick={props.onEdit}
                                        fontSize="2xl"
                                        themeColor="blue"
                                        variant="ghost"
                                        rounded="lg"
                                        type="button"
                                        _focus={{ boxShadow: "outline" }}
                                    />
                                )}
                            </Stack>
                        </>
                    );
                }}
            </Editable>
        </RHWrapper>
    );
};
