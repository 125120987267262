import React from "react";
import { AnimatePresence } from "framer-motion";

import { BaseBoxProps, Portal } from "Atoms";
import { ModalContent, ModalOverlay } from "./components";
import { UseModalProps, UseModalReturn, useModal } from "./useModal";
import { createContext } from "../../utils/createContext";

type ModalSizes = "full" | "xs" | "2xs" | "3xs" | "sm" | "md" | "lg" | "xl" | "xxl" | BaseBoxProps["maxWidth"];
type MotionPreset = "scale" | "none";
type ModalPlacements = "top" | "center" | "bottom";

interface ModalOptions {
    /**
     * Determines if focus lock is enabled.
     * - When `false`, focus lock is entirely disabled, allowing interaction with surrounding elements.
     * - ⚠️ Caution: Disabling may reduce modal accessibility per WAI-ARIA guidelines.
     *
     * @default true
     */
    trapFocus?: boolean;
    blockScrollOnMount?: boolean;
    allowPinchZoom?: boolean;
    preserveScrollBarGap?: boolean;
    removeScrollBar?: boolean;
}

interface ModalProps extends UseModalProps, ModalOptions, BaseBoxProps {
    children: React.ReactNode;
    motionPreset?: MotionPreset;
    onCloseCallback?: () => void;
    dimmerProps?: DimmerProps;
    overrideOverflow?: boolean;
    placement?: ModalPlacements;
    size?: ModalSizes;
    isScrolling?: boolean;
    dimmer?: DimmerProps;
    isDrawer?: boolean;
    overlayProps?: BaseBoxProps;
}

type DimmerProps = {
    inverted?: boolean;
    strength?: string;
};

interface ModalContext extends ModalOptions, UseModalReturn {
    motionPreset?: MotionPreset;
    size?: ModalSizes;
    overrideOverflow?: boolean;
    placement?: ModalPlacements;
}

const [ModalContextProvider, useModalContext] = createContext<ModalContext>({ strict: true, name: "ModalContext" });
export { useModalContext };

export const Modal: React.FC<ModalProps> = props => {
    const modalProps: ModalProps = {
        trapFocus: true,
        blockScrollOnMount: true,
        motionPreset: "scale",
        allowPinchZoom: true,
        overrideOverflow: false,
        placement: "center",
        removeScrollBar: true,
        isScrolling: true,
        size: "md",
        closeOnEscape: false,
        closeOnDimmerClick: false,
        dimmer: {},
        ...props
    };

    const {
        children,
        blockScrollOnMount,
        allowPinchZoom,
        preserveScrollBarGap,
        motionPreset,
        onCloseCallback,
        placement,
        overrideOverflow,
        size,
        trapFocus
    } = modalProps;

    const modal = useModal(modalProps);

    const context = {
        ...modal,
        blockScrollOnMount,
        allowPinchZoom,
        preserveScrollBarGap,
        motionPreset,
        placement,
        overrideOverflow,
        size,
        trapFocus
    };

    return (
        <ModalContextProvider value={context}>
            <AnimatePresence onExitComplete={onCloseCallback}>
                {context.open && (
                    <Portal>
                        <>
                            {!modalProps.isDrawer ? (
                                <>
                                    <ModalOverlay {...modalProps.overlayProps} />
                                    <ModalContent {...modalProps.dimmer} {...props} />
                                </>
                            ) : (
                                children
                            )}
                        </>
                    </Portal>
                )}
            </AnimatePresence>
        </ModalContextProvider>
    );
};
