import React, { useState, createContext, useContext } from "react";

import { BaseBoxProps, Box, Button, ButtonProps } from "Atoms";
import { RadioButtonGroup, RadioButtonGroupProps } from "Molecules";
import { useTheme } from "ThemeProvider";
import { calculateTipValue } from "OnlineUtils";

type ITipContext = {
    tip: number;
    onSetTip: (tip: number, isCustomTip?: boolean) => void;
};

type ITip = {
    totalPrice: number;
    defaultTip?: number;
    onChange?: (tip: number, isCustomTip: boolean) => void;
    wrapperProps?: BaseBoxProps;
};

const TipContext = createContext<ITip & ITipContext>(null as any);

const useTip = () => {
    const ctx = useContext(TipContext);
    if (!ctx) {
        throw new Error("useTip must be within TipProvider");
    }
    return ctx;
};

type TipChildren = { children(props: ITipContext): React.ReactNode } | { children: React.ReactNode };

type TipProps = ITip & TipChildren;
export const Tip: React.FC<TipProps> = ({ totalPrice, defaultTip, onChange, children, wrapperProps }) => {
    const [tip, setTip] = useState(() => {
        if (defaultTip) {
            return defaultTip;
        } else {
            return 0;
        }
    });

    const onSetTip = (tip: number, isCustomTip: boolean = false) => {
        setTip(Number(tip));
        onChange && onChange(Number(tip), isCustomTip);
    };

    const childProps = {
        tip,
        onSetTip
    };

    const contextValues = {
        totalPrice,
        ...childProps
    };

    return (
        <TipContext.Provider value={contextValues}>
            <Box borderStyle="solid" borderWidth="1px" borderColor="gray.300" rounded="lg" {...wrapperProps}>
                {typeof children === "function" ? children(contextValues) : children}
            </Box>
        </TipContext.Provider>
    );
};

export const TipBody: React.FC<BaseBoxProps> = props => <Box p={4} {...props} />;

export type TipContentProps = BaseBoxProps;
export const TipContent: React.FC<TipContentProps> = ({ children, ...rest }) => <Box {...rest}>{children}</Box>;

export type TipOptionProps = {
    value: number;
} & ButtonProps;
export const TipOption: React.FC<TipOptionProps> = ({ value, children, ...rest }) => {
    const { totalPrice, tip } = useTip();
    const { colors } = useTheme(); // "blue.500" didn't work

    const tipValue = value * (totalPrice! / 100);
    // If total price is zero, all tips will be zero. All will be selected
    const isChecked = totalPrice > 0 && tipValue === tip;

    let styles = {};
    if (isChecked) {
        styles = {
            outlineWidth: "2px",
            outlineStyle: "solid",
            outlineColor: colors.blue["500"],
            outlineOffset: "-2px",
            bg: "blue.100"
        };
    } else {
        styles = {
            borderWidth: "1px",
            borderColor: "gray.200",
            borderStyle: "solid",
            outlineColor: colors.blue["500"]
        };
    }

    return (
        <Button
            minH="3.5rem"
            variant="outline"
            themeColor="blue"
            flexDirection="column"
            flex="1"
            fontWeight="normal"
            value={tipValue}
            _focus={{
                boxShadow: "outline",
                zIndex: 1
            }}
            {...styles}
            {...rest}
        >
            {children}
        </Button>
    );
};

export type TipOptionsProps = Partial<RadioButtonGroupProps>;
export const TipOptions: React.FC<TipOptionsProps> = ({ children, ...rest }) => {
    const { tip, onSetTip, totalPrice } = useTip();

    return (
        <RadioButtonGroup
            rounded="lg"
            width="100%"
            activeThemeColor="blue"
            onValueChanged={tipPercentage => {
                const tipValue = calculateTipValue(+tipPercentage, totalPrice);
                onSetTip(tipValue, false);
            }}
            {...rest}
            value={tip}
        >
            {children}
        </RadioButtonGroup>
    );
};
