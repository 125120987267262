import React, { useCallback, useRef } from "react";

import { callAllHandlers, mergeRefs } from "FunctionUtils";
import { modalManager, useModalManager } from "./modalManager";

export type UseModalProps = {
    open: boolean;
    onClose?: () => void;
    closeOnDimmerClick?: boolean;
    closeOnEscape?: boolean;
    onOverlayClick?: () => void;
    onEscape?: () => void;
    isScrolling?: boolean;
};

export const useModal = (props: UseModalProps) => {
    const {
        open,
        onClose,
        closeOnDimmerClick = false,
        closeOnEscape = false,
        onOverlayClick: onOverlayClickProp,
        onEscape,
        isScrolling
    } = props;

    const dialogRef = useRef<HTMLElement>(null);
    const overlayRef = useRef<HTMLElement>(null);

    /**
     * Hook used to manage multiple or nested modals
     */
    const index = useModalManager(dialogRef, open);

    const mouseDownTarget = useRef<EventTarget | null>(null);

    const onMouseDown = useCallback((event: React.MouseEvent) => {
        mouseDownTarget.current = event.target;
    }, []);

    const onKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key === "Escape") {
                event.stopPropagation();

                if (closeOnEscape) {
                    onClose?.();
                }

                onEscape?.();
            }
        },
        [closeOnEscape, onClose, onEscape]
    );

    const getContentProps = useCallback(
        (props = {}, ref = null) => ({
            role: "dialog",
            ...props,
            ref: mergeRefs(ref, dialogRef),
            tabIndex: -1,
            //@ts-ignore
            onClick: callAllHandlers(props?.onClick, (event: React.MouseEvent) => event.stopPropagation())
        }),
        []
    );

    const onOverlayClick = useCallback(
        (event: React.MouseEvent) => {
            event.stopPropagation();

            if (mouseDownTarget.current !== event.target) return;

            const isNotTopModal = !modalManager.isTopModal(dialogRef.current);
            if (isNotTopModal) return;

            if (closeOnDimmerClick) {
                onClose?.();
            }
        },
        [onClose, closeOnDimmerClick, onOverlayClickProp]
    );

    const getContentContainerProps = useCallback(
        (props = {}, ref = null) => ({
            ...props,
            ref: mergeRefs(ref, overlayRef),
            //@ts-ignore
            onClick: callAllHandlers(props.onClick, onOverlayClick),
            //@ts-ignore
            onKeyDown: callAllHandlers(props.onKeyDown, onKeyDown),
            //@ts-ignore
            onMouseDown: callAllHandlers(props.onMouseDown, onMouseDown)
        }),
        [onKeyDown, onMouseDown, onOverlayClick]
    );

    return {
        open,
        onClose,
        dialogRef,
        overlayRef,
        getContentProps,
        getContentContainerProps,
        index,
        isScrolling
    };
};

export type UseModalReturn = ReturnType<typeof useModal>;
