import React from "react";
import { IoMdArrowUp } from "@react-icons/all-files/io/IoMdArrowUp";

import { NewIconButton, NewIconButtonProps } from "Atoms";
import { useSwitch, useEventCallback } from "CoreHooks";

type Props = {
    offset?: number;
    component?: React.ReactElement;
} & Omit<NewIconButtonProps, "icon">;

export const ScrollToTop: React.FC<Props> = ({ offset = 400, component, ...rest }) => {
    const { open: show, onOpen: onShow, onClose: onHide } = useSwitch();

    const onScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const checkScrollTop = () => {
        if (!show && window.pageYOffset > offset) {
            onShow();
        } else if (show && window.pageYOffset <= offset) {
            onHide();
        }
    };

    useEventCallback({ eventName: "scroll", callback: checkScrollTop });

    const _children = React.isValidElement(component)
        ? React.cloneElement(component, {
              // @ts-ignore
              onClick: onScrollToTop
          })
        : null;

    if (show) {
        if (!!component) {
            return _children;
        } else {
            return <NewIconButton icon={IoMdArrowUp} onClick={onScrollToTop} {...rest} />;
        }
    }
    return null;
};
