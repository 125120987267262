import React, { forwardRef } from "react";

import { useCheckboxStyle } from "../Checkbox/useCheckboxStyles";
import { Box, BaseBoxProps, VisuallyHidden, InputBox } from "Atoms";

export interface IRadio {
    id?: string;
    name?: string;
    value?: string | number;
    themeColor?: string;
    defaultIsChecked?: boolean;
    isChecked?: boolean;
    checked?: boolean;
    isFullWidth?: boolean;
    size?: "xxl" | "xl" | "lg" | "md" | "sm";
    isDisabled?: boolean;
    disabled?: boolean;
    isInvalid?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    children?: React.ReactNode;
}

export type RadioProps = IRadio &
    Omit<BaseBoxProps, "onChange" | "ref" | "onFocus" | "onBlur" | "size"> &
    React.RefAttributes<HTMLInputElement>;

export const Radio: React.FC<RadioProps> = forwardRef(
    (
        {
            name,
            id,
            value,
            themeColor = "blue",
            defaultIsChecked,
            isChecked,
            checked,
            isFullWidth,
            size = "md",
            isDisabled,
            disabled,
            isInvalid,
            onChange,
            onBlur,
            onFocus,
            children,
            ...rest
        },
        ref
    ) => {
        const styleProps = useCheckboxStyle({
            color: themeColor,
            size,
            type: "radio"
        });

        const _isChecked = !!isChecked || !!checked;
        const _isDisabled = !!isDisabled || !!disabled;

        return (
            <Box
                id={id}
                as="label"
                display="inline-flex"
                verticalAlign="top"
                alignItems="center"
                width={isFullWidth ? "full" : undefined}
                cursor={_isDisabled ? "not-allowed" : "pointer"}
                {...rest}
            >
                <VisuallyHidden
                    as="input"
                    type="radio"
                    id={id}
                    ref={ref}
                    name={name}
                    value={value}
                    defaultChecked={defaultIsChecked}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    checked={_isChecked}
                    disabled={_isDisabled}
                />
                <InputBox {...(styleProps as any)} type="radio" rounded="full">
                    <Box bg="currentColor" as="span" rounded="full" size="50%" />
                </InputBox>
                {children && (
                    <Box ml={2} fontSize={size} userSelect="none" w="full" opacity={_isDisabled ? 0.32 : 1}>
                        {children}
                    </Box>
                )}
            </Box>
        );
    }
);
