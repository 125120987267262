import React from "react";

import { BaseBoxProps, MotionDiv, fadeConfig } from "Atoms";
import { useModalContext } from "../Modal";

export type ModalOverlayProps = BaseBoxProps;

export const ModalOverlay: React.FC<ModalOverlayProps> = props => {
    const { motionPreset } = useModalContext();

    const motionProps: any = motionPreset === "none" ? {} : fadeConfig;

    return (
        <MotionDiv
            position="fixed"
            left="0"
            top="0"
            width="100vw"
            height="100vh"
            zIndex="modal"
            backgroundColor="blackAlpha.500"
            {...motionProps}
            {...props}
        />
    );
};
