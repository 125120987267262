import React from "react";
import { IoIosClose } from "@react-icons/all-files/io/IoIosClose";
import { components, MultiValueProps } from "react-select";

import { Box } from "Atoms";

export const RHSelectInputValueRemove = (props: MultiValueProps<any>) => {
    if (props.data.isFixed) {
        return <></>;
    }

    return (
        <>
            {/** @ts-ignore */}
            <components.MultiValueRemove {...props}>
                <Box as={IoIosClose} size="2rem" />
            </components.MultiValueRemove>
        </>
    );
};
