import React, { useEffect, useState } from "react";

import { Flex, Box, PseudoBox, BaseBoxProps } from "Atoms";

export type Props = {
    text?: string;
    placement: "right" | "top" | "bottom";
    content?: JSX.Element | JSX.Element[];
    enabled?: boolean;
    customHoverEvent?: () => void;
} & BaseBoxProps;

const boxPlacement: any = (placement: string, bgColor: string) => {
    const background = bgColor ? bgColor : "newPrimary";

    const thePlacement: any = {
        right: {
            left: "25px",
            top: "-5px",
            _before: {
                content: JSON.stringify(""),
                color: `${background}`,
                position: "absolute",
                borderTop: "8px solid transparent",
                borderBottom: "8px solid transparent",
                borderRight: "8px solid",
                left: "-5px",
                top: "9px"
            }
        },
        top: {
            bottom: "55px",
            left: "-10px",
            _before: {
                content: JSON.stringify(""),
                color: `${background}`,
                position: "absolute",
                borderLeft: "8px solid transparent",
                borderRight: "8px solid transparent",
                borderTop: "8px solid",
                bottom: "-6px",
                left: "12px"
            }
        },
        bottom: {
            left: "0px",
            top: "27px",
            _before: {
                content: JSON.stringify(""),
                color: `${background}`,
                position: "absolute",
                borderLeft: "8px solid transparent",
                borderRight: "8px solid transparent",
                borderBottom: "8px solid",
                left: "3px",
                top: "-7px"
            }
        }
    };
    return thePlacement[placement];
};

const textContainer: any = (boxPosition: string, backgroundColor: string, color: string, width: string) => {
    const bgColor = backgroundColor ? backgroundColor : "gray.300";
    const txtColor = color ? color : "black";
    const boxWidth = width ? width : "max-content";
    return {
        position: "absolute",
        backgroundColor: bgColor,
        color: txtColor,
        padding: "0.5rem",
        borderRadius: "5px",
        display: "inline-block",
        fontSize: "sm",
        maxWidth: "350px",
        width: boxWidth,
        zIndex: 100,
        ...boxPlacement(boxPosition, bgColor)
    };
};

export const ToolTip: React.FC<Props> = ({
    children,
    text,
    placement,
    content,
    enabled = true,
    customHoverEvent,
    ...rest
}) => {
    const [show, setShow] = useState<boolean>(false);
    const isValid = React.isValidElement(content);

    const textPostion = placement ? placement : "right";
    const contentText = text ? text : "No Content Available";

    const { backgroundColor, color, width } = rest;
    useEffect(() => {
        if (show) {
            customHoverEvent && customHoverEvent();
        }
    }, [show]);

    return (
        <Flex
            position="relative"
            cursor="pointer"
            w="100%"
            onMouseEnter={() => setShow(enabled)}
            onMouseLeave={() => setShow(false)}
            onClick={() => setShow(prevShow => !prevShow)}
            {...rest}
        >
            {children}
            {show && (
                <PseudoBox {...textContainer(textPostion, backgroundColor, color, width)}>
                    {isValid ? content : contentText}
                </PseudoBox>
            )}
        </Flex>
    );
};
