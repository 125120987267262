import React, { useState } from "react";
import { useController, UseFormClearErrors, UseFormSetError, useWatch } from "react-hook-form";
import { ExecutionResult, MutationFunctionOptions, OperationVariables } from "react-apollo";
import { AiOutlineCloudUpload } from "@react-icons/all-files/ai/AiOutlineCloudUpload";
import { MdErrorOutline } from "@react-icons/all-files/md/MdErrorOutline";

import { filetypeConstants } from "Constants";
import { BaseBoxProps, FormLabel, Flex, Input, RHCommonProps, InputProps, Fade } from "Atoms";
import { fileToBase64String } from "Utils";
import { DropZoneDisplay } from "./DropZoneDisplay";
import { Languagekey, useLanguage } from "Providers/languageProvider/LanguageProvider";

type FolderType = keyof typeof filetypeConstants;

type ImageDropZoneProps = {
    companyId: string;
    dropZoneProps?: BaseBoxProps;
    setError: UseFormSetError<any>;
    clearErrors: UseFormClearErrors<any>;
    folderType?: FolderType;
    dropZoneText?: Languagekey;
    acceptedFileTypes?: string;
    maxImageMBSize?: number;
    isDragAndDropDisabled?: boolean;
    passBackImageFile?: (file: File) => void;
    uploadMutation: (
        options?: MutationFunctionOptions<any, OperationVariables> | undefined
    ) => Promise<ExecutionResult<any>>;
} & RHCommonProps &
    InputProps &
    BaseBoxProps;

export const fileTypesAllowed = "image/jpeg, image/png, image/gif, image/bmp";

/**
 * [RHF] Image Upload Drop Zone
 * * @param maxImageMBSize is the maximum size of the image in MB [default: 5] converted to bytes
 * * @param passBackImageFile is a function that will pass the file back to the parent component,
 * * @param clearErrors is a function from react-hook-form that will clear the error
 * * @param setError is a function from react-hook-form that will set the error
 * however, the file will not be uploaded to the server (will be validated)
 */
export const RHImageUploadDropZone: React.FC<ImageDropZoneProps> = ({
    name,
    control,
    formLabel,
    helperText,
    dropZoneProps,
    dropZoneText,
    companyId,
    setError,
    clearErrors,
    folderType = "COMPANY",
    isDisabled = false,
    isMandatory = false,
    isFullWidth = true,
    acceptedFileTypes = fileTypesAllowed,
    maxImageMBSize = 5,
    isDragAndDropDisabled = false,
    passBackImageFile,
    uploadMutation,
    ...rest
}) => {
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [backgroundColour, setBackgroundColour] = useState<string>("white");

    const { translate, translateWithArgument } = useLanguage();

    const {
        field: { onChange: onChangeInternal, onBlur, ref },
        fieldState: { error }
    } = useController({
        name,
        control
    });

    /** Need to watch the image url - display image in smaller version */
    const imageUrl = useWatch({ control, name });

    /** Validation checks */
    const acceptedFiles = fileTypesAllowed?.split(",").map(file => file.trim());
    /** MB to bytes */
    const maxImageByteSize = maxImageMBSize * 1024 * 1024;

    const iconImage = !error ? AiOutlineCloudUpload : MdErrorOutline;
    const iconColour = !error ? "blue.500" : "gray.900";

    /** Upload image to the server - this can be skipped if a function has been provided */
    const onHandleImageUpload = async (file: File) => {
        setIsUpdating(true);
        try {
            const base64String = await fileToBase64String(file);
            const { data } = await uploadMutation({
                variables: {
                    base64Imgs: [
                        {
                            companyId,
                            imageFolder: filetypeConstants[folderType],
                            ...base64String
                        }
                    ]
                }
            });
            if (data) {
                const { uploadFile: imageUrl } = data;
                onChangeInternal(imageUrl[0]);
                clearErrors(name);
            } else {
                setError(name, { message: translate("somethingWentWrong") });
            }
        } catch (error) {
            setError(name, { message: translate("somethingWentWrong") });
        }
        setIsUpdating(false);
    };

    /** Validate image file with file type and size */
    const fileCanBeUploaded = (file: File) => {
        if (!acceptedFiles?.includes(file.type)) {
            setError(name, { message: translate("WRONG_TYPE") });
            return false;
        }
        if (file.size > maxImageByteSize) {
            setError(name, { message: translateWithArgument("TOO_LARGE", maxImageMBSize) });
            return false;
        }
        return true;
    };

    const onDropOrSelectImage = (file: File) => {
        if (fileCanBeUploaded(file)) {
            if (!passBackImageFile) {
                onHandleImageUpload(file);
            } else {
                passBackImageFile(file);
            }
        }
    };

    const dropZoneMessage = !!error ? error.message : translate(dropZoneText as Languagekey);

    return (
        <>
            <Fade in unmountOnExit={true}>
                {formLabel && <FormLabel>{formLabel}</FormLabel>}
            </Fade>
            <Flex
                mb={4}
                mt={3}
                p={2}
                width="100%"
                backgroundColor="white"
                borderColor="gray.600"
                borderRadius="lg"
                boxShadow="xs"
                border={"1px solid #EDF2F7"}
                {...rest}
            >
                <FormLabel
                    style={{
                        cursor: isDisabled ? "not-allowed" : "pointer"
                    }}
                    textAlign="center"
                    fontWeight="sm"
                    borderRadius="lg"
                    backgroundColor={backgroundColour}
                    border={"1px dashed #718096"}
                    width="100%"
                    p={4}
                    onDragLeave={(ev: React.DragEvent<HTMLDivElement>) => {
                        setBackgroundColour("white");
                        ev.preventDefault();
                    }}
                    onDragEnter={(ev: React.DragEvent<HTMLDivElement>) => {
                        if (isDragAndDropDisabled) {
                            return false;
                        }
                        ev.stopPropagation();
                        setBackgroundColour("blue.100");
                        ev.preventDefault();
                    }}
                    onDragOver={(ev: React.DragEvent<HTMLDivElement>) => {
                        if (isDragAndDropDisabled) {
                            return false;
                        }
                        ev.stopPropagation();
                        ev.preventDefault();
                    }}
                    onDrop={(ev: React.DragEvent<HTMLDivElement>) => {
                        if (isDragAndDropDisabled) {
                            return false;
                        }
                        ev.preventDefault();
                        const files = ev.dataTransfer.files;
                        if (files.length === 1) {
                            onDropOrSelectImage(files[0]);
                        }
                        setBackgroundColour("white");
                    }}
                    {...dropZoneProps}
                >
                    <Input
                        name={name}
                        onChange={(event: { currentTarget: { files: any } }) => {
                            const allFiles = [...event.currentTarget.files];
                            if (allFiles.length === 1) {
                                onDropOrSelectImage(allFiles[0]);
                            }
                        }}
                        type="file"
                        display="none"
                        padding="none"
                        accept={fileTypesAllowed}
                        isDisabled={isDisabled}
                        onBlur={onBlur}
                        ref={ref}
                    />
                    <DropZoneDisplay
                        isUploading={isUpdating}
                        imageUrl={imageUrl}
                        icon={iconImage}
                        iconColour={iconColour}
                        hasErrors={!!error}
                        dropZoneMessage={dropZoneMessage}
                    />
                </FormLabel>
            </Flex>
        </>
    );
};
