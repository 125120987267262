/**
 * Popper Component
 *
 * The following code is a derivative of the work done by the Material UI team.
 * Original source: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Popper/Popper.js
 */

import React, {
    forwardRef,
    useEffect,
    useLayoutEffect,
    useRef,
    useImperativeHandle,
    useState,
    useCallback
} from "react";
import PopperJS from "popper.js";

import { Box, BaseBoxProps, PseudoBox, PseudoBoxProps, Portal } from "../";
import { useForkRef } from "CoreHooks";
import { createChainedFunction, setRef } from "FunctionUtils";

type RenderProps = {
    placement?: PopperJS.Placement;
    transitionProps?: object;
};

type PopperChildren =
    | {
          children: React.ReactNode;
      }
    | { children: (props: RenderProps) => React.ReactNode };

export interface IPopper {
    /*
     * The reference element should be an HTML Element instance or a referenceObject:
     * https://popper.js.org/popper-documentation.html#referenceObject.
     */
    anchorEl?: null | PopperJS.ReferenceObject | (() => PopperJS.ReferenceObject);
    container?: any;
    usePortal?: boolean;
    unmountOnExit?: boolean;
    /*
     * This is used to configure how Popper.js computes the positioning of the popper.
     *
     * To ensure the modifiers you pass are performant, [learn how to create a modifier](https://github.com/FezVrasta/popper.js/blob/master/docs/_includes/popper-documentation.md#modifiers--object).
     */
    modifiers?: PopperJS.Modifiers;
    isOpen?: boolean;
    placement?: PopperJS.Placement;
    /*
     * Options provided to the [`popper.js`](https://github.com/FezVrasta/popper.js) instance.
     */
    popperOptions?: PopperJS.PopperOptions;
    popperRef?: React.RefObject<PopperJS>;
    willUseTransition?: boolean;
    hasArrow?: boolean;
    arrowSize?: string;
    arrowShadowColor?: string;
    gutter?: number;
}

export type PopperProps = IPopper & PseudoBoxProps & PopperChildren;

function flipPlacement(placement: PopperJS.Placement) {
    const direction = (typeof window !== "undefined" && document.body.getAttribute("dir")) || "ltr";

    if (direction !== "rtl") {
        return placement;
    }

    switch (placement) {
        case "bottom-end":
            return "bottom-start";
        case "bottom-start":
            return "bottom-end";
        case "top-end":
            return "top-start";
        case "top-start":
            return "top-end";
        default:
            return placement;
    }
}

function getAnchorEl(anchorEl: any) {
    return typeof anchorEl === "function" ? anchorEl() : anchorEl;
}

const useEnhancedEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect;

export const Popper: React.FC<PopperProps> = forwardRef(
    (
        {
            anchorEl,
            children,
            gutter,
            container,
            usePortal = true,
            unmountOnExit = true,
            modifiers,
            isOpen,
            placement: initialPlacement = "bottom",
            popperOptions = {},
            popperRef: popperRefProp,
            willUseTransition = false,
            arrowSize,
            arrowShadowColor,
            hasArrow,
            ...rest
        },
        ref
    ) => {
        const tooltipRef = useRef(null);
        const ownRef = useForkRef(tooltipRef, ref);

        const popperRef = useRef<PopperJS>(null);
        const handlePopperRef = useForkRef(popperRef, popperRefProp ? popperRefProp : null);
        const handlePopperRefRef = useRef(handlePopperRef);

        useEnhancedEffect(() => {
            handlePopperRefRef.current = handlePopperRef;
        }, [handlePopperRef]);

        //@ts-ignore
        useImperativeHandle(popperRefProp, () => popperRef.current, []);

        const [exited, setExited] = useState(true);

        const rtlPlacement = flipPlacement(initialPlacement);

        const [placement, setPlacement] = useState(rtlPlacement);
        if (rtlPlacement !== placement) {
            setPlacement(rtlPlacement);
        }

        const handleOpen = useCallback(() => {
            const popperNode = tooltipRef.current;

            if (!popperNode || !anchorEl || !isOpen) {
                return;
            }

            if (popperRef.current && handlePopperRefRef.current) {
                popperRef.current.destroy();
                handlePopperRefRef?.current(null);
            }

            const handlePopperUpdate = (data: PopperJS.Data) => {
                setPlacement(data.placement);
            };

            const popper = new PopperJS(getAnchorEl(anchorEl), popperNode, {
                placement: rtlPlacement,
                ...popperOptions,
                modifiers: {
                    ...(usePortal && {
                        preventOverflow: {
                            boundariesElement: "window"
                        }
                    }),
                    ...modifiers,
                    ...popperOptions.modifiers
                },
                onUpdate: createChainedFunction(handlePopperUpdate, popperOptions.onUpdate)
            });
            //@ts-ignore
            handlePopperRefRef.current(popper);
        }, [anchorEl, usePortal, modifiers, isOpen, rtlPlacement, popperOptions]);

        const handleRef = useCallback(
            //@ts-ignore
            node => {
                setRef(ownRef, node);
                handleOpen();
            },
            [ownRef, handleOpen]
        );

        const handleEnter = () => {
            setExited(false);
        };

        const handleClose = () => {
            if (popperRef.current && handlePopperRefRef.current) {
                popperRef.current.destroy();
                handlePopperRefRef.current(null);
            }
        };

        const handleExited = () => {
            setExited(true);
            handleClose();
        };

        useEffect(() => {
            handleOpen();
        }, [handleOpen]);

        useEffect(() => {
            return () => {
                handleClose();
            };
        }, []);

        useEffect(() => {
            if (!isOpen && !willUseTransition) {
                handleClose();
            }
        }, [isOpen, willUseTransition]);

        if (unmountOnExit && !isOpen && (!willUseTransition || exited)) {
            return null;
        }

        const childProps: RenderProps = { placement };

        if (willUseTransition) {
            childProps.transitionProps = {
                in: isOpen,
                onEnter: handleEnter,
                onExited: handleExited
            };
        }

        return (
            <Portal>
                <PseudoBox ref={handleRef} position="absolute" {...rest}>
                    {/** @ts-ignore */}
                    {typeof children === "function" ? children(childProps) : children}
                </PseudoBox>
            </Portal>
        );
    }
);

export const PopperArrow = (props: BaseBoxProps) => (
    <Box x-arrow="" role="presentation" background="inherit" {...props} />
);
