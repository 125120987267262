import React from "react";
import { MenuProps, components } from "react-select";

import { ModalTransition } from "Molecules";

export const RHSelectInputMenu = (props: MenuProps<any, any>) => (
    <ModalTransition preset="scale" position="absolute" top="100%" zIndex={1400} w="100%" bg="white" mt={4}>
        {/* @ts-ignore */}
        <components.Menu {...props}>{props.children}</components.Menu>
    </ModalTransition>
);
