/**
 * NOTE: Intended to be used primarily with molecules/RadioButtonGroup
 * Usage Example:
 *
    <RadioButtonGroup
        name="priceTypes"
        marginTop={2}
        marginBottom={2}
        activeThemeColor="green"
        defaultValue={defaultPriceTypeCategory}
        value={priceTypeCategory}
        onValueChanged={(selectedValue: string) => {
            setPriceTypeCategory(selectedValue);
        }}
        roundedRadius={5}
    >
        <CustomRadioButton
            value="option1"
            label="Option 1"
        >
            Option 1
        </CustomRadioButton>
        <CustomRadioButton
            value="option2"
            label="Option 2"
        >
            Option 2
        </CustomRadioButton>
    </RadioButtonGroup>

 * If the buttons get to complex, we can inherit from CustomRadioButton for specific cases
 */

import React from "react";
import { Button } from "Atoms";
import { ButtonProps } from "../Button/Button";

type Props = {
    value?: string | number;
    isChecked?: boolean;
    color?: string;
} & ButtonProps;

export const CustomRadioButton: React.FC<Props> = ({ value, children, isChecked = false, color, ...rest }) => {
    return (
        <Button
            value={value}
            themeColor={isChecked ? "red" : "gray"}
            color={!isChecked ? color : "#ffffff"}
            role="radio"
            {...rest}
            borderRadius={0}
        >
            {children}
        </Button>
    );
};
