import React from "react";
import { useController } from "react-hook-form";
import CreatableSelect, { CreatableProps } from "react-select/creatable";
import { ActionMeta, Props, ValueType } from "react-select";

import { RHCommonProps, RHWrapper, InputProps } from "Atoms";
import { useRHSelectInputStyles } from "./RHSelectInput/useRHSelectInputStyles";
import { SelectOption } from "./RHSelectInput";
import {
    RHSelectInputClearIndicator,
    RHSelectInputDropdownIndicator,
    RHSelectInputMenu,
    RHSelectInputValueRemove
} from "./RHSelectInput/components";

type SelectTypes = Omit<Props, "name"> & { isClearable?: boolean };
type RHCreatableSelectInputProps = CreatableProps<SelectOption, boolean> &
    RHCommonProps &
    SelectTypes &
    Pick<InputProps, "placeholder">;

/**
 * Note here for using creatable with creating a list away from this (Addons as an example)
 * Override onCreateOption and onChange (see types in component)
 * @example onCreateOption={(input: string) => //Do something to add to a list}
 * @example onChange={ (option: ValueType<SelectOption, boolean>, actionMeta: ActionMeta<SelectOption>) => onChangeForm()}
 */
export const RHCreatableSelectInput: React.FC<RHCreatableSelectInputProps> = ({
    name,
    control,
    formLabel,
    placeholder,
    helperText,
    isMandatory = false,
    isFullWidth = true,
    children,
    wrapperProps,
    options,
    isMulti,
    isClearable = true,
    ...rest
}) => {
    const {
        field: { onChange: onChangeInternal, onBlur, value, ref }
    } = useController({
        name,
        control,
        defaultValue: ""
    });

    const onChange = (option: ValueType<SelectOption, boolean>, actionMeta: ActionMeta<SelectOption>) => {
        if (isMulti) {
            const selectedOptions = option as SelectOption[];
            switch (actionMeta.action) {
                case "select-option":
                case "create-option": {
                    onChangeInternal(selectedOptions.map(v => v.value));
                    break;
                }
                case "remove-value":
                case "pop-value": {
                    onChangeInternal(value.filter((v: any) => v != actionMeta?.removedValue?.value));
                    break;
                }
                case "clear": {
                    onChangeInternal([]);
                    break;
                }
            }
        } else {
            const selectedOption = option as SelectOption;
            switch (actionMeta.action) {
                case "select-option": {
                    onChangeInternal(selectedOption.value);
                    break;
                }
            }
        }
    };

    const onHandleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Delete" || event.key === "Backspace") {
            !isClearable && event.preventDefault();
        }
    };

    const styles = useRHSelectInputStyles();

    // Values passed down to react-select from options
    // As this is a creatable select values will not appear in the options
    // so futher parts need to be done
    let selectedValues = isMulti
        ? options.filter((opt: SelectOption) => value.includes(opt.value))
        : options.find((opt: SelectOption) => opt.value == value);

    if (isMulti && !!value) {
        // if multi with options && create option
        // get the values that don't appear in the options
        const createdValues = value
            ?.filter((value: string) => !options?.some((opt: SelectOption) => opt.value === value))
            ?.map((value: string) => ({ label: value, value }));

        // if available add to selectedValues
        if (createdValues.length) {
            selectedValues = [...selectedValues, ...createdValues];
        }
    } else if (!isMulti && !!value) {
        // Single selection if in options take option
        // if not the create Select option from the value
        selectedValues =
            options?.find((opt: SelectOption) => opt.value === value) ?? ({ label: value, value } as SelectOption);
    }

    /**
     *  {...(!isMulti && { onCreateOption: onChangeInternal })}
     * this is only for single creatable selects if it is a mutli select with create it passes to the onChange function.
     * However if this a single one and you create a value, it doesn't pass to it - so just need to pass this straight to the
     * form on change
     */
    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={formLabel}
            helperText={helperText}
            wrapperProps={wrapperProps}
            isMandatory={isMandatory}
        >
            {/** @ts-ignore */}
            <CreatableSelect
                {...(!isMulti && { onCreateOption: onChangeInternal })}
                onChange={onChange}
                ref={ref}
                onBlur={onBlur}
                value={selectedValues}
                placeholder={placeholder}
                isClearable={isClearable}
                isMulti={isMulti}
                options={options}
                menuPortalTarget={document.body}
                styles={styles}
                clearValueOnReset={false}
                onKeyDown={onHandleKeyDown}
                {...rest}
                components={{
                    Menu: RHSelectInputMenu,
                    MultiValueRemove: RHSelectInputValueRemove,
                    DropdownIndicator: RHSelectInputDropdownIndicator,
                    ClearIndicator: RHSelectInputClearIndicator
                }}
            />
        </RHWrapper>
    );
};
