import { useCallback, useEffect, useState } from "react";

import { useSwitch } from "CoreHooks";

export type UseReadMoreProps = {
    isExpanded?: boolean;
    isTruncated?: boolean;
    wasTruncated?: boolean;
    onTruncate?: (truncate: boolean) => void;
    onToggleIsExpanded?: () => void;
    lines?: number | false | undefined;
    ellipsis?: React.ReactNode | undefined;
    trimWhitespace?: boolean | undefined;
};

export const useReadMore = (props: UseReadMoreProps) => {
    const { lines, ellipsis, trimWhitespace, ...rest } = props;

    const { open: isExpanded, onToggle: onToggleIsExpanded } = useSwitch();
    const { open: isTruncated, onToggle: onToggleIsTruncated } = useSwitch();

    const [wasTruncated, setWasTruncated] = useState(false);

    // Update local state when text becomes truncated
    useEffect(() => {
        if (isTruncated) {
            setWasTruncated(true);
        }
    }, [isTruncated]);

    const onTruncate = useCallback(
        (truncated: boolean) => {
            if (isTruncated !== truncated) {
                onToggleIsTruncated();
            }
        },
        [isTruncated]
    );

    return {
        isExpanded,
        isTruncated,
        wasTruncated,
        onToggleIsExpanded,
        onTruncate,
        lines,
        ellipsis,
        trimWhitespace,
        rest
    };
};

export type UseReadMoreReturn = ReturnType<typeof useReadMore>;
