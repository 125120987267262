import React from "react";

import { Input, InputProps } from "Atoms";
import { useKeypad } from "../KeyPad";

export const KeypadInput: React.FC<InputProps> = props => {
    const { value, onOpen, onChange, inputRef, hideInputWhenClosed, isOpen } = useKeypad();

    if (hideInputWhenClosed && !isOpen) {
        return null;
    }

    return (
        <Input
            ref={inputRef}
            value={value}
            onFocus={onOpen}
            onChange={(event: any) => onChange(event.target.value)}
            {...props}
        />
    );
};
