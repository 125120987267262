import React, { createContext, forwardRef, useContext } from "react";

import { BaseBoxProps, Box } from "Atoms";
import { UseReadMoreProps, UseReadMoreReturn, useReadMore } from "./hooks";
import { MaybeRenderProp } from "Types";
import { runIfFn } from "FunctionUtils";

type RenderProps = Pick<UseReadMoreReturn, "isExpanded" | "isTruncated" | "onToggleIsExpanded">;

interface BaseReadMoreProps extends BaseBoxProps {}

// @ts-ignore - Need to be fixed typescript error
export interface ReadMoreProps extends BaseReadMoreProps, Omit<UseReadMoreProps, "children"> {
    children: MaybeRenderProp<RenderProps>;
}

const ReadMoreContext = createContext<Omit<UseReadMoreReturn, "rest">>(null as any);

export const useReadMoreContext = () => {
    const ctx = useContext(ReadMoreContext);
    if (!ctx) {
        throw new Error("useReadMore must be within ReadMoreProvider");
    }

    return ctx;
};

export const ReadMore: React.FC<ReadMoreProps> = forwardRef((props, ref) => {
    const { rest, ...context } = useReadMore(props);

    const { isExpanded, isTruncated, onTruncate, onToggleIsExpanded, wasTruncated, lines, trimWhitespace, ellipsis } =
        context;

    const children = runIfFn(props.children, {
        isExpanded,
        isTruncated,
        onToggleIsExpanded
    });

    return (
        <ReadMoreContext.Provider
            value={{
                isExpanded,
                isTruncated,
                onTruncate,
                onToggleIsExpanded,
                wasTruncated,
                lines,
                trimWhitespace,
                ellipsis
            }}
        >
            <Box ref={ref} {...rest}>
                {children}
            </Box>
        </ReadMoreContext.Provider>
    );
});
