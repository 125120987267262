import React from "react";
import { IoIosClose } from "@react-icons/all-files/io/IoIosClose";
import { components, IndicatorProps } from "react-select";

import { PseudoBox } from "Atoms";

export const RHSelectInputClearIndicator = (props: IndicatorProps<any, any>) => {
    return (
        <>
            {/** @ts-ignore */}
            <components.ClearIndicator {...props}>
                <PseudoBox
                    as={IoIosClose}
                    ml={2}
                    size="2rem"
                    rounded="md"
                    _hover={{ backgroundColor: "gray.400", color: "gray.900" }}
                />
            </components.ClearIndicator>
        </>
    );
};
