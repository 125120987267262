import { centerCrop, makeAspectCrop, PixelCrop } from "react-image-crop";
import { IMAGE_FORMAT } from "Types";
import { FileNameAndExtension, ScaledCrop } from "./types";

const TO_RADIANS = Math.PI / 180;

/**
 * [FUNCTION] - Convert canvas into blob
 * @param canvas
 * @param format
 * @returns
 */
const toBlob = async (canvas: HTMLCanvasElement, format: string): Promise<Blob | null> => {
    return new Promise(resolve => {
        canvas.toBlob(blob => resolve(blob), format);
    });
};

/**
 * [FUNCTION] - centre aspect crop based on image width and height
 */
export const centreAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
    return centerCrop(
        makeAspectCrop(
            {
                unit: "%",
                width: 90
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
};

/**
 * [FUNCTION] - Get Image Blob / crop based on crop, scale and rotate
 * @param {HTMLImageElement} currentImage - Current Image
 * @param {PixelCrop} crop - cropped area of the image
 * @param {number} scale - scale of rendered image
 * @param {number} rotate - rotation of the image (currently not being used - step 2)
 * @param {IMAGE_FORMAT} format - image extension format
 * @returns
 */
export const getImageCropped = async (
    currentImage: HTMLImageElement,
    crop: PixelCrop,
    scale: number = 1,
    rotate: number = 0,
    format: IMAGE_FORMAT = IMAGE_FORMAT.PNG
) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
        return new Error("No 2d context found");
    }
    const scaleX = currentImage.naturalWidth / currentImage.width;
    const scaleY = currentImage.naturalHeight / currentImage.height;
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio;
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = currentImage.naturalWidth / 2;
    const centerY = currentImage.naturalHeight / 2;

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 3) Rotate around the origin - Not available YET
    ctx.rotate(rotateRads);
    // 2) Scale the image - Not available YET
    ctx.scale(scale, scale);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
        currentImage,
        0,
        0,
        currentImage.naturalWidth,
        currentImage.naturalHeight,
        0,
        0,
        currentImage.naturalWidth,
        currentImage.naturalHeight
    );

    ctx.restore();

    const blob = await toBlob(canvas, `image/${format}`);
    if (!blob) {
        throw new Error("Failed to crop image");
    }

    return blob;
};

/**
 * [FUNCTION] - convert Blob to File - used to save the cropped image
 * @param blob
 * @param fileName
 * @returns
 */
export const convertBlobToFile = (blob: Blob, fileName: string): File => {
    const file = new File([blob], fileName, { type: blob.type });
    return file;
};

/**
 * [FUNCTION] get image file extension from url
 * @param imageUrl
 * @returns
 */
export const getImageFileExtension = (imageUrl: string): IMAGE_FORMAT | null => {
    /** Step 1: Remove query */
    const removeQueryFromUrl = imageUrl.split("?");

    const imageUrlWithNoQuery = !!removeQueryFromUrl.length ? removeQueryFromUrl[0] : imageUrl;

    /** Step 2: Check Url Pattern */
    const urlPattern = /^(http|https):\/\/[^ "]+$/;

    if (!urlPattern.test(imageUrlWithNoQuery)) {
        throw new Error("Invalid URL: " + imageUrl);
    }

    /** Step 3: Get File name */
    const fileName = imageUrlWithNoQuery.substring(imageUrlWithNoQuery.lastIndexOf("/") + 1);

    /** Step 4: Get Image extension */
    let fileExtension = fileName.split(".").pop();

    if (fileExtension?.toLowerCase() === IMAGE_FORMAT.JPEG) {
        fileExtension = IMAGE_FORMAT.JPG;
    }

    return !!fileExtension ? (fileExtension.toLowerCase() as IMAGE_FORMAT) ?? null : null;
};

/**
 * [FUNCTION] - Get Image File Name and Extension
 * @param imageUrl
 * @returns
 */
export const getImageFileNameAndExtension = (imageUrl: string): FileNameAndExtension | null => {
    const fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);

    const imageFormat = getImageFileExtension(imageUrl);

    return {
        fileName: fileName.replace(/\.[^/.]+$/, ""),
        fileExtension: imageFormat
    };
};

/**
 * [FUNCTION] - Get New Image File Name [cropped image name]
 * @param nameAndExtension
 * @returns
 */
export const getNewImageFileName = (
    nameAndExtension: FileNameAndExtension,
    scaledCrop: ScaledCrop,
    isACircularCrop: boolean = false
) => {
    const pixelCropName = `${scaledCrop.naturalWidth}x${scaledCrop.naturalHeight}`;
    if (isACircularCrop) {
        `${nameAndExtension.fileName}_${pixelCropName}_circular_cropped.${nameAndExtension.fileExtension}`;
    }
    return `${nameAndExtension.fileName}_${pixelCropName}_cropped.${nameAndExtension.fileExtension}`;
};
