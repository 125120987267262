import React from "react";
import ReactJsonView, { OnCopyProps, ReactJsonViewProps } from "react-json-view";

/**
 * [WRAPPER_COMPONENT] - if you want to copy you have to use a function to copy the object
 * * Created a wrapper to just change the import so copy is default and will copy to the clipboard
 * * object = JSON.stringify(copy) && other = copy the value
 * @param param0
 * @returns
 */
export const ReactJson: React.FC<ReactJsonViewProps> = ({ ...rest }) => {
    /** On copy to clipboard it will either copy value or object to json stringify */
    const onCopyToClipboard = (copy: OnCopyProps) => {
        if (typeof copy.src === "object") {
            navigator.clipboard.writeText(JSON.stringify(copy));
        } else {
            navigator.clipboard.writeText(copy.src);
        }
    };

    return <ReactJsonView enableClipboard={onCopyToClipboard} {...rest} />;
};
