import React from "react";
import { motion, HTMLMotionProps } from "framer-motion";
import styled from "styled-components";
import {
    space,
    color,
    typography,
    layout,
    flexbox,
    grid,
    background,
    border,
    position,
    shadow,
    compose,
    borderRadius
} from "styled-system";

import { BaseBoxProps, specialConfig } from "../";

const styleProps = compose(
    space,
    color,
    typography,
    layout,
    flexbox,
    grid,
    background,
    border,
    position,
    shadow,
    borderRadius,
    specialConfig
);

export const styledMotion = (component: keyof JSX.IntrinsicElements | React.ComponentType<any>) => {
    return styled(component)<BaseBoxProps>`
        ${styleProps}
    `;
};

export type MotionDivProps = HTMLMotionProps<"div"> & BaseBoxProps;

export const MotionDiv = styledMotion(motion.div);
export const MotionSection = styledMotion(motion.section);
