import React from "react";
import { useController } from "react-hook-form";

import { BaseBoxProps, RHCommonProps, RHWrapper } from "Atoms";
import { RadioButtonGroup, RadioButtonGroupProps } from "Molecules";

export type RHRadioButtonGroupProps = {
    textAlign?: "right" | "left";
} & RHCommonProps &
    RadioButtonGroupProps &
    BaseBoxProps;

export const RHRadioButtonGroup: React.FC<RHRadioButtonGroupProps> = ({
    formLabel,
    helperText,
    isMandatory = false,
    textAlign = "right",
    control,
    name,
    children,
    defaultValue,
    wrapperProps,
    ...rest
}) => {
    const {
        field: { onChange, value }
    } = useController({
        name,
        control
    });

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={formLabel}
            helperText={helperText}
            wrapperProps={wrapperProps}
            isMandatory={isMandatory}
        >
            <RadioButtonGroup {...rest} mb={0} value={value} onValueChanged={onChange} defaultValue={defaultValue}>
                {children}
            </RadioButtonGroup>
        </RHWrapper>
    );
};
