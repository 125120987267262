import React from "react";
import { components, IndicatorProps } from "react-select";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";

import { Box } from "Atoms";

export const RHSelectInputDropdownIndicator = (props: IndicatorProps<any, any>) => {
    const { menuIsOpen } = props.selectProps;
    return (
        <>
            {/** @ts-ignore */}
            <components.DropdownIndicator {...props}>
                <Box
                    as={FaChevronDown}
                    transform={menuIsOpen ? "rotate(180)" : undefined}
                    transition="transform 0.2s"
                />
            </components.DropdownIndicator>
        </>
    );
};
