import React from "react";
import { Control, useController } from "react-hook-form";

import {
    Button,
    Input,
    InputProps,
    Popup,
    PopupBody,
    PopupContent,
    RHCommonProps,
    RHWrapper,
    Stack,
    PopupTrigger
} from "Atoms";

type TimeFormInputProps = {
    placeholder?: string;
    formLabel?: string;
    helperText?: string;
    isMandatory?: boolean;
    isFullWidth?: boolean;
    control: Control<any>;
    name: string;
    options: string[];
} & InputProps &
    RHCommonProps;

export const RHTimeFormInput: React.FC<TimeFormInputProps> = ({
    name,
    options,
    control,
    placeholder,
    formLabel,
    helperText,
    isMandatory = false,
    isFullWidth = true,
    isDisabled,
    wrapperProps,
    ...rest
}) => {
    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { isTouched, error }
    } = useController({
        name,
        control,
        defaultValue: ""
    });

    const isInvalid = !!(isTouched && error?.message);

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={formLabel}
            helperText={helperText}
            wrapperProps={wrapperProps}
            isMandatory={isMandatory}
        >
            <Popup placement="bottom-start">
                <PopupTrigger>
                    <Input
                        size="lg"
                        rounded="lg"
                        onChange={onChange} // send value to hook form
                        onBlur={() => {
                            //     setIsFocused(false);
                            onBlur();
                        }} // notify when input is touched/blur
                        value={value} // input value
                        name={name} // send down the input name
                        ref={ref}
                        isDisabled={isDisabled}
                        isInvalid={isInvalid}
                        placeholder={placeholder}
                        fullWidth={isFullWidth}
                        {...rest}
                        mb={0}
                    />
                </PopupTrigger>
                <PopupContent _focus={{}} border={"none"} boxShadow={"none"} bg={"none"} outline={"none"}>
                    <PopupBody>
                        <Stack isInline position="absolute" bottom={0} zIndex={1}>
                            {options.map((time: string) => {
                                return (
                                    <Button
                                        size="sm"
                                        key={time}
                                        width="4rem"
                                        py={0}
                                        px={2}
                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            onChange(event.currentTarget.innerText);
                                        }}
                                    >
                                        {time}
                                    </Button>
                                );
                            })}
                        </Stack>
                    </PopupBody>
                </PopupContent>
            </Popup>
        </RHWrapper>
    );
};
