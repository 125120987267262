import React from "react";

import { BaseBoxProps, NewGrid as Grid, Skeleton, GridProps } from "Atoms";

type Props = {
    columns: number;
    rows: number;
    hasHeader?: boolean;
    gridProps?: GridProps;
    headerProps?: BaseBoxProps;
} & BaseBoxProps;

export const LoadingSkeleton: React.FC<Props> = ({
    columns,
    rows,
    hasHeader = false,
    gridProps,
    headerProps,
    ...rest
}) => {
    const numberOfElements = columns * rows;
    return (
        <>
            {hasHeader && <Skeleton width="100%" height="5rem" mb={4} key={0} {...headerProps} />}
            <Grid
                templateColumns={`repeat(${columns}, 1fr)`}
                gap={3}
                justifyContent="center"
                my="0.5rem"
                alignItems="center"
                {...gridProps}
            >
                {[...Array(numberOfElements)].map((_, index: number) => {
                    return <Skeleton width="100%" height="5rem" key={index + 1} {...rest} />;
                })}
            </Grid>
        </>
    );
};
