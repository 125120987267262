import css from "@styled-system/css";
import styled from "styled-components";

import { Box, BaseBoxProps } from "../Box/Box";

const active = "&:active";
const after = "&:after";
const before = "&:before";
const focus = "&:focus";
const hover = "&:hover";
const visited = "&:visited";
const disabled = "&:disabled";
const firstChild = "&:first-of-type";
const lastChild = "&:last-of-type";
const selected = "&[aria-selected=true]";
const invalid = "&[aria-invalid=true]";
const readOnly = "&[aria-readonly=true], &[readonly]";

interface IPseudoBox {
    _active?: BaseBoxProps;
    _after?: BaseBoxProps;
    _before?: BaseBoxProps;
    _focus?: BaseBoxProps;
    _hover?: BaseBoxProps;
    _visited?: BaseBoxProps;
    _disabled?: BaseBoxProps;
    _firstChild?: BaseBoxProps;
    _lastChild?: BaseBoxProps;
    _selected?: BaseBoxProps;
    _invalid?: BaseBoxProps;
    _readOnly?: BaseBoxProps;
}

export type PseudoBoxProps = IPseudoBox & BaseBoxProps;

export const tx = (props: any): any => {
    let res = {};
    for (let prop in props) {
        res = { ...res, [prop]: props[prop] };
    }
    return res;
};

export const PseudoBox = styled(Box)<PseudoBoxProps>(({ color, ...props }) => {
    return css({
        [hover]: tx(props._hover),
        [focus]: tx(props._focus),
        [active]: tx(props._active),
        [visited]: tx(props._visited),
        [disabled]: tx(props._disabled),
        [selected]: tx(props._selected),
        [invalid]: tx(props._invalid),
        [readOnly]: tx(props._readOnly),
        [after]: tx(props._after),
        [before]: tx(props._before),
        [firstChild]: tx(props._firstChild),
        [lastChild]: tx(props._lastChild)
    });
});
