import React from "react";

import { Button, ButtonProps } from "Atoms";

type KeypadButton = {
    value: string | number;
} & ButtonProps;

export const KeypadButton: React.FC<KeypadButton> = ({ value, ...props }) => {
    const baseStyles = {
        flex: "1 0 33%",
        fontSize: "4xl",
        p: 0,
        fontWeight: 500,
        size: "12",
        rounded: 0,
        type: "button"
    };

    return (
        <Button {...(baseStyles as any)} {...props}>
            {props.children}
        </Button>
    );
};
