import React from "react";

import { BaseBoxProps, Box } from "../Box/Box";

export type TextProps = BaseBoxProps;

export const Text: React.FC<TextProps> = React.forwardRef((props, ref) => {
    //@ts-ignore
    return <Box as="p" {...props} ref={ref} />;
});
