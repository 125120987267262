import React, { forwardRef, PropsWithChildren } from "react";
import Truncate from "react-truncate";
import styled from "styled-components";

import { Box, BaseBoxProps, useReadMoreContext } from "Atoms";

export type ReadMoreTruncateProps = BaseBoxProps & PropsWithChildren;

// @ts-ignore
const StyledTruncate = styled(Truncate)`
    > * > span {
        line-height: 1.7 !important;
    }

    > * > p {
        line-height: 1.7 !important;
    }
`;

export const ReadMoreTruncate: React.FC<ReadMoreTruncateProps> = forwardRef((props, ref) => {
    const { children, ...rest } = props;
    const { isExpanded, lines, ellipsis, trimWhitespace, onTruncate } = useReadMoreContext();

    return (
        <Box ref={ref} {...rest}>
            <StyledTruncate
                lines={!isExpanded && lines}
                ellipsis={ellipsis}
                trimWhitespace={trimWhitespace}
                onTruncate={onTruncate}
            >
                {children}
            </StyledTruncate>
        </Box>
    );
});
