import React from "react";
import { useForm, UseFormProps, FieldValues, UseFormReturn, FormProvider } from "react-hook-form";

type Props<T extends FieldValues> = {
    useFormProps: UseFormProps<T>;
    children: (props: UseFormReturn<T, any>) => React.ReactNode;
};

/**
 * @description so when it comes to child components use the useFormContext from react hook form
 * @example <RHForm<Discount> useFormProps={{ defaultValues: initialValues, resolver: yupResolver(schema), mode: "onBlur" }}>
        {props => (
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>

            </form>
        )}
    </RHForm>
* @example <RHForm<Discount> useFormProps={{ defaultValues: initialValues, resolver: yupResolver(schema), mode: "onBlur" }}>
        {({handleSubmit, getValues}) => (
            <form onSubmit={handleSubmit(onSubmit)}>

            </form>
        )}
    </RHForm>
* @example const { control, setValue, trigger } = useFormContext<T>();
 */
export const RHForm = <T extends FieldValues>({ useFormProps, children }: Props<T>) => {
    const formMethods = useForm<T>({
        ...useFormProps
    });

    return <FormProvider {...formMethods}>{children(formMethods)}</FormProvider>;
};
