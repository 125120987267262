import React from "react";

import { PseudoBox, PseudoBoxProps } from "../PseudoBox/PseudoBox";
import { colors } from "../../theme/colors";

type LabelVariants = "solid" | "outline";

interface ILabel {
    size?: "sm" | "md" | "lg";
    variant?: LabelVariants;
    themeColor?: string;
    circular?: boolean;
}

export type LabelProps = ILabel & PseudoBoxProps;

const labelSizes = {
    lg: {
        minHeight: "2.5rem",
        minWidth: "2.5rem",
        fontSize: "md",
        paddingX: 4
    },
    md: {
        minHeight: "2.25rem",
        minWidth: "2.25rem",
        fontSize: "sm",
        paddingX: 2
    },
    sm: {
        minH: 6,
        minW: 6,
        fontSize: "sm",
        px: 2
    }
};

const getSolidStyles = ({ themeColor }: UseLabelStyle) => {
    if (themeColor === "gray") {
        return {
            bg: "gray.300",
            color: "gray.900"
        };
    } else {
        return {
            bg: `${themeColor}.500`,
            color: "white",
            _hover: {
                bg: `${themeColor}.700`,
                cursor: "pointer"
            }
        };
    }
};

const getOutlineStyles = ({ themeColor }: UseLabelStyle) => {
    //@ts-ignore
    const boxShadowColor = colors[themeColor] && colors[themeColor][500];
    return {
        boxShadow: `inset 0 0 0px 2px ${boxShadowColor}`,
        color: "black"
    };
};

const getVariantStyle = (props: UseLabelStyle) => {
    if (props.variant === "solid") {
        return getSolidStyles(props);
    } else if (props.variant === "outline") {
        return getOutlineStyles(props);
    }
};

const getCircularStyles = () => {
    return {
        borderRadius: "50%",
        p: 0,
        justifyContent: "center"
    };
};

type UseLabelStyle = {
    themeColor: string;
    variant: string;
    circular: boolean;
};

const useLabelStyle = (props: UseLabelStyle) => {
    const circularStyles = props.circular ? getCircularStyles() : undefined;

    return {
        ...getVariantStyle(props),
        ...circularStyles
    };
};

export const Label: React.FC<LabelProps> = ({
    themeColor = "gray",
    variant = "solid",
    size = "md",
    circular = false,
    ...props
}) => {
    const styles = useLabelStyle({ themeColor, variant, circular });

    // @ts-ignore
    const sizeStyles = labelSizes[size];

    return (
        <PseudoBox
            display="inline-flex"
            alignItems="center"
            minHeight={8}
            maxWidth="100%"
            rounded="md"
            fontWeight="normal"
            {...sizeStyles}
            {...styles}
            {...props}
        />
    );
};
