import React, { FC, useState } from "react";
import { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useController } from "react-hook-form";
import * as Yup from "yup";

import { RHCommonProps, RHWrapper } from "Atoms";
import { appendDefaultCountryCodeIfMissing, extractDialCode, validatePhoneNumber } from "Utils";
import { StyledPhoneInput } from "./components";

export type RHPhoneInputWithCountryCodeProps = {
    onlyShowLabelWithValue?: boolean;
    relatedSwishField?: string;
    countryCode?: string;
} & RHCommonProps &
    PhoneInputProps;

const DEFAULT_INPUT_MASK = ".. ... .. .. ..";

export const yupPhoneNumberValidation = () => {
    return Yup.string().test("phoneNumber", "", (value?: any) => {
        const { error } = validatePhoneNumber(value);

        if (!error) {
            return true;
        }

        return new Yup.ValidationError(error, "dunno", "phoneNumber");
    });
};

export const RHPhoneInputWithCountryCode: FC<RHPhoneInputWithCountryCodeProps> = ({
    name,
    control,
    formLabel,
    helperText,
    isMandatory,
    isFullWidth,
    wrapperProps,
    ...rest
}) => {
    const [country, setCountry] = useState({ countryCode: new Intl.Locale("sv-SE").region, dialCode: "46" });

    const {
        field: { onChange, onBlur, value, ref }
    } = useController({
        name,
        control
    });

    const updateCountryCode = (newCountryCode: string, dialCode: string) => {
        const hasCountryCodeChanged = newCountryCode !== country.countryCode;
        if (hasCountryCodeChanged) {
            setCountry({ countryCode: newCountryCode, dialCode: dialCode });
        }
    };

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={formLabel}
            helperText={helperText}
            wrapperProps={wrapperProps}
            isMandatory={isMandatory}
        >
            <StyledPhoneInput
                onBlur={onBlur}
                inputProps={{ name, ref }}
                value={value}
                country={"se"}
                masks={{ se: DEFAULT_INPUT_MASK }}
                placeholder="+46 000000000"
                onChange={(phone: string, country: any) => {
                    const updatedPhoneNumber = appendDefaultCountryCodeIfMissing(phone);
                    updateCountryCode(country.countryCode.toUpperCase(), country.dialCode);
                    onChange(updatedPhoneNumber);
                }}
                autocompleteSearch={true}
                onMount={(_: string, country: any) => {
                    updateCountryCode(country.countryCode.toUpperCase(), country.dialCode);
                }}
                countryCodeEditable={false}
                {...rest}
            />
        </RHWrapper>
    );
};
