import React, { forwardRef } from "react";

import { Button, ButtonProps, useReadMoreContext } from "Atoms";
import { useLanguage } from "Providers/languageProvider/LanguageProvider";

export type ReadMoreButtonProps = {
    expandedText?: string;
    collapsedText?: string;
} & ButtonProps;

export const ReadMoreButton: React.FC<ReadMoreButtonProps> = forwardRef((props, ref) => {
    const { expandedText, collapsedText, ...rest } = props;

    const { onToggleIsExpanded, isExpanded, wasTruncated } = useReadMoreContext();
    const { translate } = useLanguage();

    const defaultExpandedText = translate("close");
    const defaultCollapsedText = translate("readMore");

    const text = isExpanded ? expandedText || defaultExpandedText : collapsedText || defaultCollapsedText;

    // Render the button only when text is or was truncated
    if (wasTruncated) {
        return (
            <Button ref={ref} variant="link" onClick={onToggleIsExpanded} {...rest}>
                {text}
            </Button>
        );
    }

    return null;
});
