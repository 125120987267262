import React from "react";
import { useController } from "react-hook-form";
import Select, { GroupProps } from "react-select";
import { ActionMeta, ValueType } from "react-select";

import { RHCommonProps, RHWrapper, InputProps } from "Atoms";
import { useRHSelectInputStyles } from "./RHSelectInput/useRHSelectInputStyles";
import { SelectOption } from "./RHSelectInput/RHSelectInput";
import {
    RHSelectInputMenu,
    RHSelectInputValueRemove,
    RHSelectInputDropdownIndicator,
    RHSelectInputClearIndicator
} from "./RHSelectInput/components";

export type GroupSelectedOption = {
    label: string;
    options: SelectOption[];
};

type RHGroupSelectInputProps = RHCommonProps &
    Partial<GroupProps<GroupSelectedOption, boolean>> &
    Pick<InputProps, "placeholder">;

export const RHGroupSelectInput: React.FC<RHGroupSelectInputProps> = ({
    name,
    control,
    formLabel,
    placeholder,
    helperText,
    isMandatory = false,
    isFullWidth = true,
    children,
    wrapperProps,
    options,
    isMulti,
    ...rest
}) => {
    const {
        field: { onChange: onChangeInternal, onBlur, value }
    } = useController({
        name,
        control,
        defaultValue: ""
    });

    const onChange = (option: ValueType<SelectOption, boolean>, actionMeta: ActionMeta<SelectOption>) => {
        if (isMulti) {
            const selectedOptions = option as SelectOption[];
            switch (actionMeta.action) {
                case "select-option": {
                    onChangeInternal(selectedOptions.map(v => v.value));
                    break;
                }
                case "remove-value": {
                    onChangeInternal(value.filter((v: any) => v != actionMeta?.removedValue?.value));
                    break;
                }
                case "clear": {
                    onChangeInternal([]);
                    break;
                }
            }
        } else {
            const selectedOption = option as SelectOption;
            switch (actionMeta.action) {
                case "select-option": {
                    onChangeInternal(selectedOption.value);
                    break;
                }
            }
        }
    };

    const styles = useRHSelectInputStyles();

    const flatOptions = options!.flatMap(option => option.options);
    let selectedValues = isMulti
        ? flatOptions.filter((opt: SelectOption) => value.includes(opt.value))
        : flatOptions.find((opt: SelectOption) => opt.value == value);

    if (!isMulti && !!value) {
        selectedValues =
            flatOptions?.find((opt: SelectOption) => opt.value === value) ?? ({ label: value, value } as SelectOption);
    }

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={formLabel}
            helperText={helperText}
            wrapperProps={wrapperProps}
            isMandatory={isMandatory}
        >
            {/** @ts-ignore */}
            <Select
                onChange={onChange}
                onBlur={onBlur}
                value={selectedValues}
                placeholder={placeholder}
                isMulti={isMulti}
                options={options}
                menuPortalTarget={document.body}
                isClearable={isMulti}
                styles={styles}
                {...rest}
                components={{
                    Menu: RHSelectInputMenu,
                    MultiValueRemove: RHSelectInputValueRemove,
                    DropdownIndicator: RHSelectInputDropdownIndicator,
                    ClearIndicator: RHSelectInputClearIndicator
                }}
            />
        </RHWrapper>
    );
};
